import React from 'react'
import { useParams } from 'react-router-dom';

function ReviewSinglePackage({ orderPackage }) {
  const { order_id } = useParams();
  const id = parseInt(order_id.split(":")[1]);
  const order = orderPackage.find((task) => task.id === id).order

  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6  red">
      <div className="p-4 bg-blue-100 rounded flex justify-between items-center">
        <div className="flex items-center gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="size-6 text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6"
            />
          </svg>

          <h4 className="font-medium text-sm text-blue-500">
            <span className="font-bold">Order : </span>
            {order.subject}
          </h4>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
      <div
        className="transition-transform duration-300 opacity-100 translate-y-0
         bg-white p-4"
      >
        <h3 className="text-lg font-semibold">Order Information</h3>
        <p className="mt-1 text-sm text-gray-500">
          Review the details of your order package.
        </p>

        <div className="mt-4 border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="px-4 py-2 flex justify-between ">
              <dt className="text-sm font-medium text-gray-900">Order Type</dt>
              <dd className="mt-1 text-sm text-gray-700">
                {order?.order_type}
              </dd>
            </div>
            <div className="px-4 py-2 flex justify-between ">
              <dt className="text-sm font-medium text-gray-900">Discipline</dt>
              <dd className="mt-1 text-sm text-gray-700">{order?.subject}</dd>
            </div>
            <div className="px-4 py-2 flex justify-between ">
              <dt className="text-sm font-medium text-gray-900">Amount</dt>
              <dd className="mt-1 text-sm text-gray-700">${order?.amount}</dd>
            </div>
            <div className="px-4 py-2 flex justify-between ">
              <dt className="text-sm font-medium text-gray-900">
                Order Status
              </dt>
              <dd className="mt-1 text-sm text-gray-700">
                <span
                  className={`inline-flex items-center rounded-full px-2 text-xs font-medium ${
                    order?.order_status === "complete"
                      ? "bg-green-100 text-green-800"
                      : order.order_status === "pending"
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-red-100 text-red-800"
                  }`}
                >
                  {order?.order_status}
                </span>
              </dd>
            </div>
            <div className="px-4 py-4 flex flex-col justify-between gap-4">
              <dt className="text-sm font-medium text-gray-900">Details</dt>
              <dd
                className="mt-1 text-sm text-gray-700 break-words w-3/4"
                dangerouslySetInnerHTML={{
                  __html: order.order_details,
                }}
              />
            </div>
            <div className="px-4 py-2 flex justify-between ">
              <dt className="text-sm font-medium text-gray-900">Attachments</dt>
              <dd className="mt-2">
                {order.files && order.files.length > 0 ? (
                  <ul className="space-y-2">
                    {order.files.map((file, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center"
                      >
                        <span className="text-sm text-gray-700">
                          {file.name}
                        </span>
                        <a
                          href={file.url}
                          download
                          className="text-indigo-600 hover:text-indigo-500"
                        >
                          Download
                        </a>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <span className="text-sm text-gray-500">No attachments</span>
                )}
              </dd>
            </div>
          </dl>
        </div>

        <div className="p-4 bg-blue-100 rounded flex justify-between items-center">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-6 text-blue-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
              />
            </svg>

            <h4 className="font-medium text-sm text-blue-500">
              <span className="font-bold">Order package: </span>
              {orderPackage.title}
            </h4>
          </div>
        </div>
        <div
          className={"transition-transform duration-300 opacity-100 translate-y-0 bg-white p-4"}
        >
          <p className="text-sm text-slate-400 break-words w-full">
            {orderPackage.content}
          </p>
          <div className="flex justify-end mt-4">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              
            >
              Send to Student
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewSinglePackage