import React, { useContext, useEffect, useState } from "react";
import banner from "../../assets/images/banner.png";
import { UrlContext } from "../../context/UrlProvider";
import toast, { Toaster } from "react-hot-toast";
import profile from "../../assets/images/profile.jpg";

function StudentIndex({
  orders,
  currentUser,
  greeting,
  toggleSidebar,
  completedOrdersCount,
}) {
  const apiUrl = useContext(UrlContext);

  const [showReviewDialog, setShowReviewDialog] = useState(false);
  const [hover, setHover] = useState(null);

  const MAX_LENGTH = 400;
  const [content, setContent] = useState("");
  const [rating, setRating] = useState(null);

  const handleReviewChange = (e) => {
    const content = e.target.value;
    if (content.length <= MAX_LENGTH) {
      setContent(content);
    }
  };

  const isReviewValid = content.length <= MAX_LENGTH;

  function handleReview(e) {
    e.preventDefault();
    if (!currentUser) {
      return;
    } else if (content === "" || rating === null) {
      toast.error("Add a comment and Rating!");
      return;
    }
    console.log("reviews");
    const token = localStorage.getItem("jwt");
    const promise = new Promise(async (resolve, reject) => {
      fetch(`${apiUrl}/reviews`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: currentUser.id,
          content,
          rating,
          allowed:false
        }),
      })
        .then((resp) => resp.json())
        .then((d) => {
          console.log(d);
          setRating(null);
          setContent("");
          setShowReviewDialog(false);
          resolve();
        })
        .catch(reject);
    });

    toast.promise(promise, {
      loading: "Sending ...",
      success: "Review sent",
      error: "An error occurred",
    });
  }

  useEffect(() => {
    if (
      completedOrdersCount > 0 &&
      completedOrdersCount % 3 === 0 &&
      currentUser.reviews.length === 0 
    ) {
      setShowReviewDialog(true);
    }
  }, [completedOrdersCount]);

  const closeDialog = () => {
    setShowReviewDialog(false);
  };

  const formatDate = (value) => {
    if (!value) {
      return;
    }
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return formattedDate;
  };

  return (
    <>
      <Toaster />
      <div className="w-full overflow-hidden red bg-red-500">
        <div className="relative h-48">
          <div className=" absolute text-white left-5 top-5">
            <div className="flex justify-start gap-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-8"
                onClick={() => toggleSidebar()}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                />
              </svg>
              <h1 className="text-4xl font-semibold mb-2">Dashboard</h1>
            </div>
          </div>
          <div className="px-6 py-4 text-white absolute bottom-0 w-full">
            <div className="flex gap-4 justify-between items-end">
              <div className="flex gap-4 justify-center items-end">
                <img
                  className="w-[70px] h-[70px] rounded-full object-cover"
                  src={banner}
                  alt="Card "
                />
                <div>
                  <p className="font-thin">{greeting},</p>
                  <h2 className="font-bold text-xl capitalize">
                    {currentUser.username}
                  </h2>
                  <p className="font-normal">{currentUser.role}</p>
                </div>
              </div>
              <button className="border shadow rounded-xl px-3 py-2 font-bold">
                View Profile
              </button>
            </div>
          </div>
          <img
            className="w-full h-[150px] object-cover rounded-tl-3xl"
            src={banner}
            alt="Card "
          />
        </div>

        <div className="">
          <div className="border">
            <div className="grid grid-cols-3 bg-gray-100 h-[10vh] border-gray-300 divide-x divide-gray-300">
              <div className="flex items-center justify-center ">
                <p>12 Resources</p>
              </div>
              <div className="flex items-center justify-center ">
                <p>12 Notifications</p>
              </div>
              <div className="flex items-center justify-center ">
                <p>12 Messages</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 ">
        <div className="col-span-8 py-4">
          <div className="flex justify-between items-middle px-6">
            <p className="font-bold">Orders</p>
            <p className="">Sort by</p>
          </div>
          <ul
            className="divide-y divide-gray-100 px-8 overflow-y-scroll h-[50vh]"
          >
            {orders.map((order) => {
              return (
                <li
                  key={order.id}
                  className="flex justify-between  gap-x-6 py-5"
                >
                  <div className="flex justify-between items-center min-w-0 gap-x-4 w-full">
                    <div className="min-w-0 flex-auto">
                      <div className="inline-flex gap-8">
                        <h2 className="font-bold">{order.order_type}</h2>
                        <span
                          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                            order.order_status === "processing"
                              ? "bg-blue-50 text-blue-600 ring-blue-500/10"
                              : order.order_status === "complete"
                              ? "bg-green-50 text-green-600 ring-green-500/10"
                              : order.order_status === "pending"
                              ? "bg-purple-50 text-purple-600 ring-purple-500/10"
                              : order.order_status === "cancelled"
                              ? "bg-red-50 text-red-600 ring-red-500/10"
                              : "bg-gray-50 text-gray-600 ring-gray-500/10" // Fallback for any unknown status
                          }`}
                        >
                          {order.order_status}
                        </span>
                      </div>
                      <p className="text-sm">
                        Due on {formatDate(order.deadline)}
                      </p>
                    </div>
                    <div>
                      <button className="shadow font-medium border px-2 py-1 rounded-lg">
                        View order
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="col-span-4 py-4 border bg-gray-100">
          <div className="flex justify-between items-middle px-8">
            <p className="font-bold">Activity feed</p>
            <p className="">View all </p>
          </div>
        </div>
        {showReviewDialog && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
              <div className="flex justify-between mb-4 align-top">
                <h2 className="text-xl font-bold text-gray-800">
                  Give a Review
                </h2>
                <button onClick={closeDialog}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <form onSubmit={(e) => handleReview(e)}>
                <div className="mb-4">
                  <div className="flex items-center gap-4 mb-4">
                    {currentUser && (
                      <div className="h-12 w-12">
                        <img
                          className="w-full h-full object-contain rounded-full"
                          src={
                            currentUser?.image?.length
                              ? currentUser.image
                              : profile
                          }
                          alt="user"
                        />
                      </div>
                    )}
                    <div className="flex flex-col">
                      <label className="text-gray-600 font-medium">
                        Rate the App
                      </label>
                      <div className="flex gap-1">
                        {[...Array(5)].map((_, index) => {
                          const currentRating = index + 1;
                          return (
                            <label key={index}>
                              <input
                                type="radio"
                                className="hidden"
                                name="rating"
                                value={currentRating}
                                onClick={() => setRating(currentRating)}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill={
                                  currentRating <= (hover || rating)
                                    ? "#FFB704"
                                    : "none"
                                }
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="0.5"
                                className="w-6 h-6 cursor-pointer"
                                onMouseEnter={() => setHover(currentRating)}
                                onMouseLeave={() => setHover(null)}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
                                />
                              </svg>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <textarea
                    className="form-control border border-gray-300 focus:ring-blue-500 focus:border-blue-500 rounded-md w-full p-2"
                    name="review"
                    maxLength={MAX_LENGTH}
                    value={content}
                    onChange={(e) => handleReviewChange(e)}
                    rows="3"
                  ></textarea>
                </div>
                <div className="mb-3 flex">
                  <button
                    type="submit"
                    disabled={!isReviewValid}
                    className={`flex-grow rounded-md py-2 px-4 font-medium ${
                      isReviewValid
                        ? "bg-blue-700 text-white"
                        : "bg-gray-300 text-gray-500"
                    }`}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default StudentIndex;
