import React, { useContext, useState } from 'react'
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Field, Label, Switch } from "@headlessui/react";
import { UrlContext } from '../context/UrlProvider';

function ContactUs({ currentUser, setInquiries, inquiries }) {
  const apiUrl = useContext(UrlContext);
  const [agreed, setAgreed] = useState(false);
  const [content, setContent] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  function handleInquiry(e) {
    e.preventDefault();
    console.log("commenting");
    const token = localStorage.getItem("jwt");
    e.preventDefault();
    fetch(`${apiUrl}/inquiries`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: currentUser.id,
        content,
        email,
        username,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        setInquiries((inquiries) => [...inquiries, data]);

        setContent("");
        setEmail("");
        setUsername("");
      });
  }
  return (
    <div className="md:container md:mx-auto px-2 sm:px-12 md:px-24 ">
      <div className="isolate bg-white px-6 py-24 sm:py-4 lg:px-8">
        <section className="text-gray-600 body-font relative">
          <div className="container px-5  py-24 mx-auto flex sm:flex-nowrap flex-wrap">
            <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-4 sm:p-10 flex items-end justify-start relative">
              <iframe
                width="100%"
                height="100%"
                className="absolute inset-0"
                frameborder="0"
                title="map"
                marginheight="0"
                marginwidth="0"
                scrolling="no"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.6128140024307!2d-118.5118595251106!3d34.4103274987394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c287caae93a3d1%3A0xa3fe4a766e8efc35!2sFerry%20Ct%2C%20Santa%20Clarita%2C%20CA%2091350%2C%20USA!5e1!3m2!1sen!2ske!4v1734733284845!5m2!1sen!2ske"
                style={{ filter: " contrast(1.2) opacity(0.4)" }}
              ></iframe>

              <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                <div className="lg:w-1/2 px-6">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    ADDRESS
                  </h2>
                  <p className="mt-1">
                    4556 Brendan Ferry Los Angeles, CA 90210
                  </p>
                </div>
                <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                    EMAIL
                  </h2>
                  <a className="text-indigo-500 leading-relaxed">
                    knowledgequesttutor@gmail.com
                  </a>
                  <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                    PHONE
                  </h2>
                  <p className="leading-relaxed">+1 404 382 6432</p>
                  <p className="leading-relaxed">+1 404 410 7296</p>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
              <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">
                Feedback
              </h2>
              <p className="leading-relaxed mb-5 text-gray-600">
                We value your opinions! Please fill out the form below to share
                your feedback or suggestions with us.
              </p>
              <form onSubmit={handleInquiry}>
                <div className="relative mb-4">
                  <label for="name" className="leading-7 text-sm text-gray-600">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={(e) => setUsername(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="relative mb-4">
                  <label
                    for="email"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </div>
                <div className="relative mb-4">
                  <label
                    for="message"
                    className="leading-7 text-sm text-gray-600"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    onChange={(e) => setContent(e.target.value)}
                    value={content}
                    required
                  ></textarea>
                </div>
                <button
                  className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg w-full"
                  type="submit"
                >
                  Submit
                </button>
              </form>
              <p className="text-xs text-gray-500 mt-3">
                Thank you for taking the time to share your thoughts with us.
                Your feedback helps us improve!
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ContactUs