import React, { useContext } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { UrlContext } from "../../context/UrlProvider";

function ContentManagement({ resources,setResources }) {
  const navigate = useNavigate();
  const apiUrl = useContext(UrlContext)

  const truncatedText = (text) => {
    const truncated = text.length > 60 ? text.substring(0, 60) + " ..." : text;
    return truncated;
  };

  const handleEditStudyMaterial = (resource_id) => {
    navigate(`/dashboard/content/edit/:${resource_id}`);
  };

  const handleDeleteStudyMaterial = (resource_id) => {
    const id = resource_id
    const token = localStorage.getItem("jwt_token");
    fetch(`${apiUrl}/resources/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(resp=>{
      if (resp.ok) {
        const value = resources.filter((resource) => resource.id === resource_id)
        setResources(value)
      } else {
        
      }
    })
  }
    

  return (
    <>
      <section className="bg-white w-full px-8 py-2 ">
        <div className="items-center justify-between md:flex">
          <div className=" w-full">
            <h2 className="mb-2 text-2xl font-semibold ">Resource Items</h2>
            <p className="text-sm font-medium text-body-color dark:text-dark-6">
              List of all resources
            </p>
          </div>
          <div>
            <button
              className="inline-flex items-center justify-center whitespace-nowrap rounded bg-blue-500 px-5 py-[10px] text-sm font-medium text-white hover:bg-opacity-90"
              onClick={() => navigate("/dashboard/content/newResource")}
            >
              Add New Item
              <span className="pl-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.99961 2.39961C5.35453 2.39961 2.39961 5.35453 2.39961 8.99961C2.39961 12.6447 5.35453 15.5996 8.99961 15.5996C12.6447 15.5996 15.5996 12.6447 15.5996 8.99961C15.5996 5.35453 12.6447 2.39961 8.99961 2.39961ZM0.599609 8.99961C0.599609 4.36042 4.36042 0.599609 8.99961 0.599609C13.6388 0.599609 17.3996 4.36042 17.3996 8.99961C17.3996 13.6388 13.6388 17.3996 8.99961 17.3996C4.36042 17.3996 0.599609 13.6388 0.599609 8.99961Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.99961 5.09961C9.49667 5.09961 9.89961 5.50255 9.89961 5.99961V11.9996C9.89961 12.4967 9.49667 12.8996 8.99961 12.8996C8.50255 12.8996 8.09961 12.4967 8.09961 11.9996V5.99961C8.09961 5.50255 8.50255 5.09961 8.99961 5.09961Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.09961 8.99961C5.09961 8.50255 5.50255 8.09961 5.99961 8.09961H11.9996C12.4967 8.09961 12.8996 8.50255 12.8996 8.99961C12.8996 9.49667 12.4967 9.89961 11.9996 9.89961H5.99961C5.50255 9.89961 5.09961 9.49667 5.09961 8.99961Z"
                    fill="white"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </section>
      <div className="container mx-auto p-8 ">
        <div className="h-full flex items-center justify-center">
          <div className="grid grid-cols-12 gap-2 gap-y-4 max-w-6xl">
            <div className="col-span-12 sm:col-span-6 md:col-span-3 shadow-md bg-white">
              <card className="w-full flex flex-col">
                <div className="h-40 w-62">
                  <img
                    src="https://picsum.photos/seed/59/300/200"
                    className="object-cover w-full h-full"
                  />
                </div>

                <div className="flex flex-row my-2 px-1">
                  <div className=" w-full">
                    <p className="text-xs tracking-wider uppercase hover:underline dark:text-violet-600">
                      Sample content type
                    </p>
                    <p className="text-gray-800 text-sm font-semibold whitespace-normal break-words">
                      Sample subject
                    </p>
                  </div>
                </div>
              </card>
            </div>
            {resources &&
              resources.map((resource) => {
                return (
                  <div className="col-span-12 sm:col-span-6 md:col-span-3 shadow-md bg-white">
                    <card className="w-full flex flex-col">
                      <div className="h-40 w-62 relative group">
                        <div className="absolute w-full h-full flex justify-end">
                          <Menu
                            as="div"
                            className="relative inline-block text-left invisible group-hover:visible "
                          >
                            <div>
                              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                                  />
                                </svg>
                              </MenuButton>
                            </div>

                            <MenuItems
                              transition
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="py-1">
                                <MenuItem>
                                  <p
                                    onClick={() =>
                                      handleEditStudyMaterial(resource.id)
                                    }
                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                  >
                                    Edit
                                  </p>
                                </MenuItem>
                              </div>

                              <div className="py-1">
                                <MenuItem>
                                  <p
                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                    onClick={() =>
                                      handleDeleteStudyMaterial(resource.id)
                                    }
                                  >
                                    Delete
                                  </p>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </Menu>
                        </div>
                        <img
                          src={
                            resource.cover_image?.length
                              ? resource.cover_image
                              : "https://picsum.photos/seed/59/300/200"
                          }
                          className="object-cover w-full h-full"
                        />
                      </div>

                      <div className="flex flex-row my-2 px-1">
                        <div className=" w-full">
                          <p className="text-xs tracking-wider uppercase hover:underline dark:text-violet-600">
                            {resource.content_type}
                          </p>
                          <p className="text-gray-800 text-sm font-semibold whitespace-normal break-words">
                            {truncatedText(resource.subject)}
                          </p>
                        </div>
                      </div>
                    </card>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentManagement;
