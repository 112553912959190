import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import attachment_icon from "../assets/images/attachment_icon.png"

function StudyMaterialOpen({ resources }) {
  const [openPlan, setOpenPlan] = useState(false);
  const navigate = useNavigate();
  const { resource_id } = useParams();
  const id = parseInt(resource_id.split(":")[1]);

  const resource = resources.find((resource) => resource.id === id);

  const sanitizedContent = DOMPurify.sanitize(resource?.content_details);

  useEffect(() => {
    if (resource?.amount !== "undefined" || resource?.amount > 0) {
      setOpenPlan(true);
    }
  }, []);

  const handleSummary = () => {
    navigate(`/materials/summary/:${id}`);
  };

  return (
    <div className="red h-full">
      <div className="md:container md:mx-auto px-2 sm:px-12 md:px-24">
        <div className="pt-20">
          {/* resource info */}
          <div className="pb-16 pt-10 lg:grid lg:max-w-7xl grid-cols-12 lg:grid-rows-[auto_auto_1fr] lg:gap-x-8 lg:pb-24 lg:pt-16">
            <div className="lg:col-span-12 mx-auto">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {resource?.subject}
              </h1>
            </div>
            <div className="lg:col-span-12 mx-auto ">
              <dl className="mt-4 items-center flex flex-wrap gap-x-4 sm:gap-y-1 ">
                <dt className="font-medium text-gray-900">Type</dt>
                <dd className="text-sm text-gray-500">
                  {resource?.content_type}
                </dd>
                <dt className="font-medium text-gray-900">Spacing</dt>
                <dd className="text-sm text-gray-500">
                  {resource?.single_spaced}
                </dd>
                <dt className="font-medium text-gray-900">Source count</dt>
                <dd className="text-sm text-gray-500">
                  {resource?.source_count}
                </dd>
                <dt className="font-medium text-gray-900">Page count</dt>
                <dd className="text-sm text-gray-500">
                  {resource?.page_count}
                </dd>
              </dl>
            </div>

            <div className="mx-auto col-span-12 mt-8">
              <div>
                <div className="max-w-2xl ">
                  <img src={resource?.cover_image} alt={resource?.id} />
                </div>
              </div>
            </div>
            <div
              className={`lg:col-span-12 lg:pb-16 lg:pr-8 overflow-hidden ${
                openPlan ? "h-[650px]" : "h-full"
              }`}
            >
              {/* Description and details */}
              <div className="mt-10">
                <h2 className="text-sm font-medium text-gray-900">Details</h2>

                <div className="mt-4 space-y-6 relative">
                  <div
                    className="whitespace-pre-wrap break-words text-sm text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: sanitizedContent,
                    }}
                  ></div>
                  <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    {resource?.files && (
                      <div className="flex justify-start items-start flex-wrap ps-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                          />
                        </svg>
                        {resource?.files?.map((file, index) => (
                          <div className="p-1 card overflow-hidden" key={index}>
                            <li className="flex items-center justify-between  px-5 text-sm/6">
                              <div>
                                <div className="flex flex-col justify-start">
                                  {file.content_type.startsWith("image/") && (
                                    <div>
                                      <img
                                        src={file.url}
                                        alt={file.name}
                                        className="w-full h-auto mt-2 rounded"
                                        style={{
                                          maxHeight: "20vh",
                                          maxWidth: "20vh",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {file.content_type.startsWith(
                                    "application/"
                                  ) && (
                                    <div>
                                      <img
                                        src={attachment_icon}
                                        alt={file.name}
                                        className="w-full h-auto mt-2 rounded"
                                        style={{
                                          maxHeight: "20vh",
                                          maxWidth: "20vh",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  )}
                                  
                                  {
                                    openPlan && !resource?.payment_status &&
                                    <div className="flex-shrink-0 mt-2">
                                      <a
                                        href={file.url}
                                        download
                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                      >
                                        Download
                                      </a>
                                    </div>
                                  }
                                </div>
                              </div>
                            </li>
                          </div>
                        ))}
                      </div>
                    )}
                  </ul>

                  {openPlan && !resource?.payment_status && (
                    <div className="absolute top-40 h-[400px]  w-full bg-gradient-to-t from-gray-100 via-gray-100 to-gray-transparent ">
                      <div className=" mx-auto lg:w-2/3   overflow-hidden ">
                        <div className="p-6 flex flex-col">
                          <h2 className="text-xl font-semibold text-gray-800 mb-4">
                            Access full stories from the top minds in the
                            industry.
                          </h2>
                          <p className="text-gray-600 mb-4">
                            The author made this story available at a premium.
                            Purchase it to instantly unlock this story.
                          </p>
                          <ul className="list-disc list-inside text-gray-600  mb-4">
                            <li>Full-time Access</li>

                            <li>Diverse perspectives and expertise</li>
                            <li>
                              Access personalized learning plans based on your
                              specific goals, strengths, and areas for
                              improvement
                            </li>
                          </ul>
                          <button
                            className="mx-auto  bg-blue-600 text-white font-medium py-2 px-4 rounded-lg hover:bg-blue-700 transition"
                            onClick={handleSummary}
                          >
                            Purchase Now
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyMaterialOpen;
