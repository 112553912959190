import React, { useContext, useEffect, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import { UrlContext } from "../../context/UrlProvider";

function ReviewsAndRatings({
  setAllReviews,
  allReviews,
  homeReviews,
  setHomeReviews,
}) {
  const apiUrl = useContext(UrlContext);

  const approvedCount = allReviews.filter(
    (review) => review.approved === true
  ).length;

  function handleReviewUpdate(e, id, status) {
    e.preventDefault();
    if (approvedCount > 9 && status === "approve") {
      toast("max-approve is 9, remove one to replace");
      return;
    }
    let approved;
    if (status === "approve") {
      approved = 1;
    } else if (status === "reject") {
      approved = 0;
    }
    const token = localStorage.getItem("jwt");
    const promise = new Promise(async (resolve, reject) => {
      fetch(`${apiUrl}/reviews/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          approved: approved,
        }),
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json();
          } else {
            reject();
          }
        })
        .then((updatedReview) => {
          if (status === "approve") {
            setHomeReviews([...homeReviews, updatedReview]);
          } else if (status === "reject") {
            const filtered = allReviews.filter((review)=> review.id === updatedReview.id)
            setHomeReviews(filtered)
          }
          resolve();
        })
        .catch(reject);
    });

    toast.promise(promise, {
      loading: "Fetching details...",
      success: "Updated",
      error: "An error occurred",
    });
  }

  function deleteReview(e, id) {
    e.preventDefault();

    const token = localStorage.getItem("jwt");
    const promise = new Promise((resolve, reject) => {
      fetch(apiUrl + `/reviews/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => {
          setAllReviews((allReviews) => {
            const updatedReviews = allReviews.filter(
              (review) => review.id !== id
            );
            return updatedReviews;
          });
          resolve();
        })
        .catch(reject);
    });
    toast.promise(promise, {
      loading: "Fetching details...",
      success: "Deleted",
      error: "An error occurred",
    });
  }

  return (
    <div>
      <Toaster />
      <div className="items-center justify-between md:flex">
        <div className=" w-full bg-white my-6 px-8 py-2">
          <h2 className="mb-2 text-2xl font-semibold ">Approved Reviews</h2>
          <p className="text-sm font-medium text-body-color dark:text-dark-6">
            List of all Approved Reviews
          </p>
        </div>
      </div>
      <div className="px-8 ">
        <div className="flex flex-wrap">
          {homeReviews &&
            homeReviews.map((review, index) => {
              return (
                <div key={index} className="flex md:w-1/3 ">
                  <div
                    className="card  text-start p-2 m-2 overflow-hidden border border-gray-200 rounded-md w-full flex flex-col justify-between "
                    
                  >
                    <div>
                      <div className="card-header border-0 flex justify-between">
                        <p className="m-0 capitalize">
                          <span className="font-semibold">Username: </span>
                          {review?.user?.username}
                        </p>
                        <p className="text-muted font-light m-0">
                          <span className="font-semibold">Stars: </span>
                          {review?.rating}
                        </p>
                      </div>
                      <div className="card-body p-2 ">
                        <div className="flex flex-col justify-between space-y-4">
                          <div className="flex flex-col items-start gap-2 mt-2 w-52">
                            <p className="text-muted m-0 font-semibold">
                              Review:
                            </p>
                            <p className="font-light italic whitespace-normal ">
                              {review?.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer flex justify-between gap-3">
                      <div className="w-16 flex  justify-center">
                        <button
                          className="btn text-blue-500 border rounded-md  p-1"
                          onClick={(e) =>
                            handleReviewUpdate(e, review.id, "reject")
                          }
                        >
                          Reject
                        </button>
                      </div>
                      <div className="w-16 flex  justify-center">
                        <button
                          className="btn text-red-500  rounded-md"
                          onClick={(e) => deleteReview(e, review.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="items-center justify-between md:flex ">
        <div className=" w-full bg-white my-6 px-8 py-2">
          <h2 className="mb-2 text-2xl font-semibold ">All Reviews</h2>
          <p className="text-sm font-medium text-body-color dark:text-dark-6">
            List of all Reviews
          </p>
        </div>
      </div>
      <div className="px-8">
        <div className="flex flex-wrap">
          {allReviews &&
            allReviews.map((review, index) => {
              return (
                <div key={index} className="flex md:w-1/3">
                  <div className="card  text-start p-2 m-2 overflow-hidden border border-gray-200 rounded-md w-full flex flex-col justify-between">
                    <div>
                      <div className="card-header border-0 flex justify-between">
                        <p className="m-0 capitalize">
                          <span className="font-semibold">Username: </span>
                          {review.user.username}
                        </p>
                        <p className="text-muted font-light m-0">
                          <span className="font-semibold">Rating: </span>
                          {review.rating}
                        </p>
                      </div>

                      <div className="card-body p-2 ">
                        <div className="flex flex-col justify-between space-y-4">
                          <div className="flex flex-col items-start gap-2 mt-2">
                            <p className="m-0 capitalize">
                              <span className="font-semibold">Approved: </span>
                              <span
                                className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                                  review.approved == 1
                                    ? "bg-blue-50 text-blue-600 ring-blue-500/10"
                                    : "bg-green-50 text-green-600 ring-green-500/10"
                                }`}
                              >
                                {review.approved == 1 ? "Active" : "Inactive"}
                              </span>
                            </p>
                            <p className="text-muted m-0 font-semibold">
                              Review:
                            </p>
                            <p className="font-light italic">
                              {review.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer flex justify-between gap-3">
                      <div className="w-16 flex  justify-center">
                        {review.approved == 1 ? (
                          <button
                            className="btn text-blue-500 border p-1 rounded-md"
                            onClick={(e) =>
                              handleReviewUpdate(e, review.id, "reject")
                            }
                          >
                            Reject
                          </button>
                        ) : (
                          <button
                            className="btn btn-info bg-blue-500 text-white px-4 py-2 rounded-md"
                            onClick={(e) =>
                              handleReviewUpdate(e, review.id, "approve")
                            }
                          >
                            Approve
                          </button>
                        )}
                      </div>
                      <div className="w-16 flex  justify-center">
                        <button
                          className="btn text-red-500  rounded-md"
                          onClick={(e) => deleteReview(e, review.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ReviewsAndRatings;
