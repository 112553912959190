import React, { useEffect, useState } from "react";
import { PaystackButton } from "react-paystack";
import { useNavigate, useParams } from "react-router-dom";

function StudyMaterialSummary({ resources, currentUser }) {
  const navigate = useNavigate();

  const { resource_id } = useParams();
  const [resource, setResource] = useState(null);
  const [email, setEmail] = useState(currentUser.email || "");
  const [isEmailValid, setIsEmailValid] = useState(true);

  // const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const publicKey = "pk_test_f31a182972d9734760e69b821d829257636dfc09";

  useEffect(() => {
    setEmail(currentUser.email);
    localStorage.setItem(`resource_id`, resource_id);
  }, [resource_id, currentUser]);

  useEffect(() => {
    const savedID = localStorage.getItem(`resource_id`);
    if (savedID) {
      const id = parseInt(resource_id.split(":")[1]);
      const filtered = resources.find((value) => value.id === id);
      setResource(filtered);
    }
  }, [resources]);

  const componentProps = {
    email,
    amount: parseFloat(resource?.amount) * 100,
    currency: "USD",
    publicKey,
    metadata: {
      resource_id: resource_id,
      user_email: email,
      resource:true
    },
    text: "Get Access",
    onSuccess: () => navigate(-1),
    onClose: () => navigate(-1),
  };

  const handlePayment = () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false);
      return;
    }
  };

  return (
    <div className="px-8 h-screen pt-20">
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
        <div className="px-4 pt-8 ">
          <button
            className=" px-2 py-1 bg-gray-50"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <p className="text-xl font-medium">Order Summary</p>
          <p className="text-gray-400">
            Check your items. And select a suitable shipping method.
          </p>
          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 ">
            <div className="flex flex-col rounded-lg bg-white sm:flex-row">
              <img
                className="m-2 sm:h-36 sm:w-28 aspect[3/2]  border object-cover object-center"
                src={
                  resource?.cover_image?.length
                    ? resource.cover_image
                    : "https://picsum.photos/seed/59/300/200"
                }
                alt=""
              />
              <div className="flex w-full flex-col px-4 py-4">
                <span className="font-semibold">{resource?.subject}</span>
                <span className="float-right text-gray-400">
                  {resource?.page_count}{" "}
                  {resource?.page_count > 1 ? "pages" : "page"}
                </span>
                <p className="text-lg font-bold">${resource?.amount}</p>
              </div>
            </div>
          </div>

          <p className="mt-8 text-lg font-medium">Resource details</p>
          <div className="flex flex-col gap-2 mt-2 w-full ">
            <p className="m-0">
              <span className="text-gray-500 font-light pr-3">Type:</span>
              {resource?.content_type}
            </p>
            <p className="m-0">
              <span className="text-gray-500 font-light pr-3">Discipline:</span>
              {resource?.subject}
            </p>
            {resource?.single_spaced === true && (
              <p className="m-0">
                <span className="text-gray-500 font-light pr-3">
                  Single spaced:
                </span>
                {resource?.single_spaced}
              </p>
            )}

            {resource?.source_count > 0 && (
              <p className="m-0">
                <span className="text-gray-500 font-light pr-3">
                  Source count:
                </span>
                {resource?.source_count}
              </p>
            )}
          </div>
        </div>
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <p className="text-xl font-medium">Finalize</p>
          <p className="text-gray-400">
            Complete your order by proceeding to checkout.
          </p>
          <div className="mt-4">
            <label
              htmlFor="email"
              className="block text-sm/6 font-semibold text-gray-900"
            >
              Email
              <span className="text-gray-500"> (Required)</span>
            </label>
            <div className="mt-2.5">
              <input
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 ${
                  !isEmailValid ? "ring-red-500" : ""
                }`}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsEmailValid(true);
                }}
                value={email}
              />
            </div>
          </div>
          <div className="my-6 flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">Total</p>
            <p className="text-2xl font-semibold text-gray-900">
              ${resource?.amount}
            </p>
          </div>
          {!email && (
            <button
              onClick={handlePayment}
              className="block w-full px-4 py-1.5 bg-[#1369A1] text-white rounded-md text-sm/6 hover:bg-[#184b6d]"
            >
              Get Access
            </button>
          )}

          {email && isEmailValid && (
            <PaystackButton
              {...componentProps}
              className="block w-full  px-4 py-1.5 bg-[#1369A1] text-white rounded-md text-sm/6 hover:bg-[#184b6d]"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StudyMaterialSummary;
