import React from "react";

function NotificationPage({ filteredNotifications, notifications }) {

  return (
    <div className="container mx-auto md:px-8">
      <div className="flex justify-center w-full mt-4">
        {filteredNotifications.length < 1 && (
          <div className="mx-auto max-w-sm  text-sm h-96 flex items-center justify-center">
            <div className="flex flex-col items-center space-y-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-12"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776"
                />
              </svg>

              <h2 className="text-xl font-bold mb-4">No Notifications</h2>
            </div>
          </div>
        )}
        {filteredNotifications.map((value) => {
          return (
            <div className="w-1/2 ">
              <div className="flex flex-col bg-white shadow-md hover:shadow-xl rounded-md pe-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-16 h-16 rounded-md p-3 border border-blue-100 text-blue-400 bg-blue-50"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <div className="flex flex-col ml-3">
                      <div className="font-medium leading-none">
                        Delete Your Acccount ?
                      </div>
                      <p className="text-sm text-gray-600 leading-none mt-1">
                        By deleting your account you will lose your all data
                      </p>
                    </div>
                  </div>
                  <button className="flex-no-shrink bg-red-500 px-2 ml-4 py-1.5 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider border-2 border-red-500 text-white rounded-md">
                    view
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default NotificationPage;
