import React, { useContext, useEffect, useState } from "react";
import { UrlContext } from "../context/UrlProvider";
import FormSectionOne from "../components/OrderFormSections/FormSectionOne";
import FormSectionTwo from "../components/OrderFormSections/FormSectionTwo";
import FormSectionThree from "../components/OrderFormSections/FormSectionThree";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Order({
  orders,
  setOrders,
  currentUser,
  openReset,
  handleSubmit,
  setCurrentUser,
  setLogged,
}) {
  const apiUrl = useContext(UrlContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [amount, setAmount] = useState();
  const [orderType, setOrderType] = useState("");
  const [subject, setSubject] = useState("");
  const [singleSpaced, setSingleSpaced] = useState(false);
  const [referencingStyle, setReferencingStyle] = useState("APA");
  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [orderID, setOrderID] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [orderDetails, setOrderDetails] = useState("");
  const [sourceCount, setSourceCount] = useState(0);
  const [deadline, setDeadline] = useState("");
  const [deliveryTime, setDeliveryTime] = useState(new Date());
  const [email, setEmail] = useState("");
  const [inputValue, setInputValue] = useState("")
  const [isChecked, setIsChecked] = useState(false);
  const [openLogIn, setOpenLogIn] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [password, setPassword] = useState("");

  function handleSubmitOrder(e) {
    e.preventDefault();
    setError("");

    const formData = new FormData();
    formData.append("user_id", currentUser?.id);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("amount", amount);
    formData.append("order_type", orderType);
    formData.append("subject", subject);
    formData.append("deadline", deliveryTime);
    formData.append("page_count", pageCount);
    formData.append("source_count", sourceCount);
    formData.append("order_details", orderDetails);
    formData.append("referencing_style", referencingStyle);
    formData.append("order_status", "pending");

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
    }

    sendOrder(formData);
  }

  // Proceed to next step
  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  // Go back to previous step
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handlePageCount = (operator) => {
    setPageCount((prevCount) => {
      let count = prevCount;
      if (operator === "+") {
        count += 1;
      } else if (operator === "-" && prevCount > 1) {
        count -= 1;
      }
      return count;
    });
  };

  const handleSourceCount = (operator) => {
    setSourceCount((prevCount) => {
      let count = prevCount;
      if (operator === "+") {
        count += 1;
      } else if (operator === "-" && prevCount > 0) {
        count -= 1;
      }
      return count;
    });
  };

  const handleSingleSpaced = () => {
    setSingleSpaced((prevVal) => !prevVal);
  };

  useEffect(() => {
    const date = deadline;
    if (date) {
      const num = parseInt(date.match(/\d+/)[0]);
      const multiplier = singleSpaced ? 2 : 1;
      const updateBudget = (timePeriod) => {
        const newBudget = multiplier * pageCount * timePeriod;
        setAmount(newBudget);
      };
      switch (num) {
        case 6:
          updateBudget(25);
          break;
        case 12:
          updateBudget(22);
          break;
        case 24:
          updateBudget(20);
          break;
        case 2:
          updateBudget(18);
          break;
        case 3:
          updateBudget(15);
          break;
        case 5:
          updateBudget(14);
          break;
        case 7:
          updateBudget(13);
          break;
        case 9:
          updateBudget(12);
          break;
        case 14:
          updateBudget(11);
          break;
        case 20:
          updateBudget(10);
          break;
        default:
          updateBudget(0);
      }
    }
  }, [pageCount, deadline, singleSpaced]);

  const handleFileChange = (files) => {
    setFiles(files);
  };

  useEffect(() => {
    const date = deadline;
    if (date !== "") {
      const str = date.match(/[a-zA-Z]+/).toString();
      const num = parseInt(date.match(/\d+/)[0]);
      const currentDateTime = new Date();
      if (str === "hrs") {
        currentDateTime.setHours(currentDateTime.getHours() + num);
        setDeliveryTime(() => currentDateTime);
      } else if (str === "days") {
        currentDateTime.setHours(currentDateTime.getHours() + num * 24);
        setDeliveryTime(() => currentDateTime);
      }
    }
  }, [deadline]);

  //! send order
  const sendOrder = async (formData) => {
    const response = await fetch(`${apiUrl}/orders`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      console.log("Network response was not ok");
    }

    const data = await response.json();
    if (data && data.user && data.jwt) {
      setCurrentUser(data.user);
      nextStep();
      localStorage.setItem("jwt", data.jwt);
      localStorage.setItem("kqt_user_id", data.user.id);
      // localStorage.setItem("task_quill_content", "");
      setOrders((data) => [...orders, data]);
      setOrderID(data.order.id);
      setLogged(true);
    } else {
      console.log("Invalid response data received.", data.error);
    }
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <FormSectionOne
            nextStep={nextStep}
            setDeadline={setDeadline}
            setOrderType={setOrderType}
            orderType={orderType}
            subject={subject}
            setSubject={setSubject}
            handlePageCount={handlePageCount}
            pageCount={pageCount}
            singleSpaced={singleSpaced}
            handleSingleSpaced={handleSingleSpaced}
          />
        );
      case 2:
        return (
          <FormSectionTwo
            nextStep={nextStep}
            prevStep={prevStep}
            setOrderDetails={setOrderDetails}
            setReferencingStyle={setReferencingStyle}
            handleFileChange={handleFileChange}
            handleSourceCount={handleSourceCount}
            sourceCount={sourceCount}
            orderDetails={orderDetails}
            handleSubmitOrder={handleSubmitOrder}
            currentUser={currentUser}
            handleLogin={handleLogin}
            setEmail={setEmail}
            email={email}
          />
        );
      case 3:
        return (
          <FormSectionThree
            pageCount={pageCount}
            deliveryTime={deliveryTime}
            orderID={orderID}
            amount={amount}
            loading={loading}
            setLoading={setLoading}
            prevStep={prevStep}
            currentUser={currentUser}
            subject={subject}
            orderType={orderType}
          />
        );
      default:
        return <h1 className="text-red-500">Unknown step</h1>;
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsEmail(isChecked);
  };

  const handleLogin = () => {
    setOpenLogIn((prevVal) => !prevVal);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const loginData = {
      isEmail,
      password,
      inputValue,
    };
    handleSubmit(loginData);
  };

  return (
    <div className="md:container md:mx-auto px-2 sm:px-12 md:px-24 ">
      <div className="flex  bg-gray-100 items-center justify-center min-h-screen pt-24 sm:pt-10 ">
        <div className="grid grid-cols-12 justify-between gap-4 md:container sm:py-16 ">
          <section className="col-span-12 ">
            <div className="mx-auto sm:container">
              <div className="border-b border-stroke">
                <h2 className="mb-2 text-2xl font-semibold">
                  Complete your order
                </h2>
              </div>
            </div>
          </section>
          <div className="col-span-12 xl:col-span-8 xl:col-start-5 p-6 bg-blue-500 lg:bg-transparent rounded-md shadow-md lg:shadow-none">
            {renderCurrentStep()}
          </div>
          {openLogIn && (
            <div className="overlay">
              <div className="max-w-md shadow-md bg-white p-8 rounded-md">
                <div className="flex justify-end mb-4 ">
                  <button className="text-end " onClick={handleLogin}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18 18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <p className="text-lg mb-4 font-bold m-0">Login Form</p>
                <form onSubmit={(e) => handleFormSubmit(e)}>
                  <div className="mb-3">
                    <input
                      type="checkbox"
                      id="emailPhoneToggle"
                      className="hidden"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor="emailPhoneToggle"
                      className="grid grid-cols-2 rounded-lg border"
                    >
                      <span
                        className={`flex justify-center items-center p-2 rounded text-sm font-medium ${
                          !isChecked
                            ? "text-primary bg-gray-300"
                            : "text-gray-700"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 36 36"
                          className="mr-2"
                        >
                          <path
                            fill="currentColor"
                            d="M32 6H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2m-1.54 22H5.66l7-7.24l-1.44-1.39L4 26.84V9.52l12.43 12.37a2 2 0 0 0 2.82 0L32 9.21v17.5l-7.36-7.36l-1.41 1.41ZM5.31 8h25.07L17.84 20.47Z"
                          />
                          <path fill="none" d="M0 0h36v36H0z" />
                        </svg>
                        Email
                      </span>

                      <span
                        className={`flex justify-center items-center p-2 rounded text-sm  font-medium ${
                          isChecked
                            ? "text-primary bg-gray-300"
                            : "text-gray-700"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 256 256"
                          className="mr-2 "
                        >
                          <path
                            fill="currentColor"
                            d="m221.59 160.3l-47.24-21.17a14 14 0 0 0-13.28 1.22a5 5 0 0 0-.56.42l-24.69 21a1.88 1.88 0 0 1-1.68.06c-15.87-7.66-32.31-24-40-39.65a1.91 1.91 0 0 1 0-1.68l21.07-25a6 6 0 0 0 .42-.58a14 14 0 0 0 1.12-13.27L95.73 34.49a14 14 0 0 0-14.56-8.38A54.24 54.24 0 0 0 34 80c0 78.3 63.7 142 142 142a54.25 54.25 0 0 0 53.89-47.17a14 14 0 0 0-8.3-14.53M176 210c-71.68 0-130-58.32-130-130a42.23 42.23 0 0 1 36.67-42h.23a2 2 0 0 1 1.84 1.31l21.1 47.11a2 2 0 0 1 0 1.67l-21.11 25.06a5 5 0 0 0-.43.57a14 14 0 0 0-.91 13.73c8.87 18.16 27.17 36.32 45.53 45.19a14 14 0 0 0 13.77-1c.19-.13.38-.27.56-.42l24.68-21a1.92 1.92 0 0 1 1.6-.1l47.25 21.17a2 2 0 0 1 1.21 2A42.24 42.24 0 0 1 176 210"
                          />
                        </svg>
                        Phone Number
                      </span>
                    </label>
                  </div>

                  <div className="mb-2 text-left">
                    <label htmlFor="email" className="form-label m-0">
                      {!isChecked ? "Email" : "Phone number"}
                    </label>
                    {!isChecked ? (
                      <input
                        type="email"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={inputValue}
                        id="email"
                        name="email"
                        onChange={(e) => setInputValue(e.target.value)}
                        required
                      />
                    ) : (
                      <PhoneInput
                        country={"us"}
                        value={inputValue}
                        onChange={(phone) => setInputValue(phone)}
                      />
                    )}
                  </div>

                  <div className="mb-2 text-left">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label m-0"
                    >
                      Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="password"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="exampleInputPassword1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="shadow-lg bg-blue-700 py-3 text-white font-bold my-4 w-full rounded"
                  >
                    Submit
                  </button>
                  <div className="pt-3 text-right">
                    <p className=" cursor-pointer" onClick={() => openReset()}>
                      <small>
                        <em>forgot password?</em>
                      </small>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Order;
