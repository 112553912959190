import React, { useEffect, useState } from "react";
import { topicsArr } from "../../assets/ArrayData/topicsArr";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Select from "react-tailwindcss-select";

function FormSectionOne({
  nextStep,
  setOrderType,
  orderType,
  setSubject,
  subject,
  handlePageCount,
  pageCount,
  handleSingleSpaced,
  singleSpaced,
  setDeadline,
}) {

  //! error handling
  const [typeBorder, setTypeBorder] = useState(false);
  const [subjectBorder, setSubjectBorder] = useState(false);
  const [deadlineBorder, setDeadlineBorder] = useState(false);

  const [selectedOrderType, setSelectedOrderType] = useState("")

  //! pricing
  const [deadlineOptions, setDeadlineOptions] = useState([]);
  const [selectedDeadline, setSelectedDeadline] = useState(null);


  useEffect(() => {
    // Helper function to calculate future dates
    const calculateDeadlineDate = (hoursToAdd) => {
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + hoursToAdd);
      return currentDate.toLocaleString("en-US", {
        weekday: "short",
        day: "numeric",
      });
    };

    // Define deadline options
    const options = [
      { value: "6 hrs", label: `6 hrs / ${calculateDeadlineDate(6)}` },
      { value: "12 hrs", label: `12 hrs / ${calculateDeadlineDate(12)}` },
      { value: "24 hrs", label: `24 hrs / ${calculateDeadlineDate(24)}` },
      { value: "2 days", label: `2 days / ${calculateDeadlineDate(24 * 2)}` },
      { value: "3 days", label: `3 days / ${calculateDeadlineDate(24 * 3)}` },
      { value: "5 days", label: `5 days / ${calculateDeadlineDate(24 * 5)}` },
      { value: "7 days", label: `7 days / ${calculateDeadlineDate(24 * 7)}` },
      { value: "9 days", label: `9 days / ${calculateDeadlineDate(24 * 9)}` },
      {
        value: "14 days",
        label: `14 days / ${calculateDeadlineDate(24 * 14)}`,
      },
      {
        value: "20 days",
        label: `20 days / ${calculateDeadlineDate(24 * 20)}`,
      },
    ];

    setDeadlineOptions(options);
  }, []);

  const handleOrderTypeChange = (value) => {
      setOrderType(value.label);
      setSelectedOrderType(value)
      setTypeBorder(false);
    
  };

  const handleDeadlineChange = (selectedOption) => {
    setDeadline(selectedOption.value);
    setSelectedDeadline(selectedOption);
    setDeadlineBorder(false);
  };

  return (
    <div>
      <div className="bg-white rounded-md shadow-lg p-4 md:p-8 mb-6">
        <div className="grid grid-cols-12 gap-4 text-sm">
          <div className="col-span-12 relative">
            <div className="absolute hidden xl:block w-1/2 ps-8 -left-3/4 ">
              <section className=" dark:bg-dark ">
                <div className="mx-auto sm:container">
                  <div>
                    <h2 className="mb-2 text-xl font-semibold ">Order type</h2>
                    <p className="mb-6 text-sm  text-body-color dark:text-dark-6">
                      Selecting the appropriate order type ensures you receive
                      the best support from our qualified tutors.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <p className="font-medium text-lg mb-4">
              Select order type and deadline
            </p>
            <p className="mb-6 text-sm xl:hidden text-body-color dark:text-dark-6">
              Selecting the appropriate order type ensures you receive the best
              support from our qualified tutors.
            </p>
          </div>
          <div className="text-gray-600 lg:col-span-6  col-span-10">
            <label className="form-label ">
              Order type<span className="text-danger">*</span>
            </label>

            <Select
              value={selectedOrderType}
              onChange={(e) => handleOrderTypeChange(e)}
              options={topicsArr}
              className={`w-full h-10 mt-1 rounded px-4 bg-gray-50 ${
                typeBorder ? "border border-danger" : ""
              }`}
            />
          </div>
          <div className="col-span-10 lg:col-span-5 md:col-start-1">
            <label className="form-label text-md">Subject</label>
            <input
              type="text"
              name="subject"
              id="price"
              value={subject}
              className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 "
              onChange={(e) => {
                setSubject(e.target.value);
                setSubjectBorder(false);
              }}
            />
          </div>

          <div className="lg:col-span-5 col-span-10 col-start-1">
            <label for="" className="form-label">
              Deadline
            </label>
            <Select
              value={selectedDeadline}
              onChange={handleDeadlineChange}
              options={deadlineOptions}
              className={`w-full h-10 mt-1 rounded px-4 bg-gray-50 ${
                deadlineBorder ? "border border-danger" : ""
              }`}
              placeholder="Select deadline"
            />
          </div>

          <div className="md:col-span-4  col-span-6 md:col-start-1">
            <label for="soda">Page count</label>
            <div className="h-10 w-28 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
              <button
                type="button"
                className="cursor-pointer outline-none focus:outline-none border-r border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                onClick={() => handlePageCount("-")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-4 w-4 mx-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 12h14"
                  />
                </svg>
              </button>
              <input
                className="px-2 text-center appearance-none outline-none text-gray-800 w-full bg-transparent"
                value={pageCount}
              />
              <button
                type="button"
                className="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                onClick={() => handlePageCount("+")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-4 w-4 mx-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="col-span-6 mt-6 md:col-start-1">
            <div className="inline-flex items-center">
              <input
                type="checkbox"
                value="singleSpaced"
                checked={singleSpaced}
                onChange={handleSingleSpaced}
              />
              <label
                className="form-check-label  text-black ml-2"
                onChange={handleSingleSpaced}
              >
                Single spaced
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right">
        <div className="inline-flex items-end">
          <button
            className="bg-blue-500 border hover:border-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => nextStep()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormSectionOne;
