import React from 'react'
import profile from "../../assets/images/profile.jpg"

function SupportAndFeedback({ inquiries,allUsers }) {
 
  return (
    <div className="container mx-auto md:px-8">
      <div className="flex justify-start w-full mt-4">
        <ul className="divide-y w-3/4 divide-gray-100">
          {inquiries &&
            inquiries.map((inquiry) => (
              <li
                key={inquiry.email}
                className="flex justify-between gap-x-6 py-5"
              >
                <div className="flex min-w-0 gap-x-4">
                  <img
                    alt={inquiry.username || "User profile"}
                    src={
                      inquiry.user_id && allUsers[inquiry.user_id]?.image
                        ? allUsers[inquiry.user_id - 1].image
                        : profile
                    }
                    className="size-12 flex-none rounded-full bg-gray-50"
                  />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm/6 font-semibold text-gray-900">
                      {inquiry.user_id
                        ? allUsers[inquiry.user_id - 1].username
                        : inquiry.username}
                    </p>
                    <p className="mt-1 truncate text-xs/5 text-gray-500">
                      {inquiry.user_id
                        ? allUsers[inquiry.user_id - 1].email
                        : inquiry.email}
                    </p>
                  </div>
                </div>
                <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm/6 text-gray-900">
                    {allUsers[inquiry.user_id].role}
                  </p>
                  {inquiry.lastSeen ? (
                    <p className="mt-1 text-xs/5 text-gray-500">
                      Last seen
                      <time dateTime={inquiry.lastSeenDateTime}>
                        {inquiry.lastSeen}
                      </time>
                    </p>
                  ) : (
                    <div className="mt-1 flex items-center gap-x-1.5">
                      <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div className="size-1.5 rounded-full bg-emerald-500" />
                      </div>
                      <p className="text-xs/5 text-gray-500">Online</p>
                    </div>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default SupportAndFeedback