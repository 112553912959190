import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home.jsx";
import "./App.css";
import About from "./pages/About.jsx";
import Navbar from "./components/Navbar.jsx";
import Login from "./pages/Login.jsx";
import { useContext, useEffect, useState } from "react";
import { UrlContext } from "./context/UrlProvider.jsx";
import Assignments from "./components/adminDashboardComponents/OrdersList.jsx";
import Order from "./pages/Order.jsx";
import Dashboard from "./pages/Dashboard.jsx";
import Index from "./components/Index.jsx";
import OrderAssignment from "./components/adminDashboardComponents/OrderAssignment.jsx";
import OrdersList from "./components/adminDashboardComponents/OrdersList.jsx";
import { CableContext } from "./context/cable.jsx";
import UsersList from "./components/adminDashboardComponents/UsersList.jsx";
import AdminSettings from "./components/adminDashboardComponents/AdminSettings.jsx";
import SupportAndFeedback from "./components/adminDashboardComponents/SupportAndFeedback.jsx";
import ContentManagement from "./components/adminDashboardComponents/ContentManagement.jsx";
import ReportAndAnalytics from "./components/adminDashboardComponents/ReportAndAnalytics.jsx";
import TutorProfileSettings from "./components/tutorDashboardComponents/TutorProfileSettings.jsx";
import TutorTasks from "./components/tutorDashboardComponents/TutorTasks.jsx";
import SingleTask from "./components/tutorDashboardComponents/SingleTask.jsx";
import MyResources from "./components/studentDashboardComponents/MyResources.jsx";
import ReceivedPackages from "./components/studentDashboardComponents/ReceivedPackages.jsx";
import StudyMaterials from "./pages/StudyMaterials.jsx";
import ReviewOrderPackage from "./components/adminDashboardComponents/ReviewOrderPackage.jsx";
import Chatroom from "./components/adminDashboardComponents/Chatroom.jsx";
import Messenger from "./components/studentDashboardComponents/Messenger.jsx";
import ResetPassword from "./pages/ResetPassword.jsx";
import NewResources from "./components/adminDashboardComponents/NewResources.jsx";
import ProfileSettings from "./components/studentDashboardComponents/ProfileSettings.jsx";
import NotificationPage from "./components/adminDashboardComponents/NotificationPage.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import NotFound from "./pages/NotFound.jsx";
import toast, { Toaster } from "react-hot-toast";
import ReviewsAndRatings from "./components/adminDashboardComponents/ReviewsAndRatings.jsx";
import ReviewSinglePackage from "./components/adminDashboardComponents/ReviewSinglePackage.jsx";
import StudyMaterialOpen from "./pages/StudyMaterialOpen.jsx";
import StudyMaterialSummary from "./components/studyMaterialComponents/StudyMaterialSummary.jsx";
import EditStudyMaterial from "./components/adminDashboardComponents/EditStudyMaterial.jsx";

function App() {
  const [orders, setOrders] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allReviews, setAllReviews] = useState([]);
  const [homeReviews, setHomeReviews] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [orderPackages, setOrderPackages] = useState([]);
  const [greeting, setGreeting] = useState("");
  const [inquiries, setInquiries] = useState([]);
  const [messages, setMessages] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [resources, setResources] = useState([]);
  const [expandSidebar, setExpandSidebar] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const apiUrl = useContext(UrlContext);
  const location = useLocation();
  const navigate = useNavigate();
  const cable = useContext(CableContext);

  // !persist user logged
  useEffect(() => {
    const token = localStorage.getItem("jwt");
    const user_id = localStorage.getItem("kqt_user_id");

    if (token && user_id) {
      const id = parseInt(user_id);

      fetch(`${apiUrl}/users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            console.error(`Error: ${resp.status}`);
          }
        })
        .then((current_user) => {
          setCurrentUser(current_user);
          setLogged(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  // !login User
  async function handleSubmit(loginData) {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Login error:", errorData);
        console.error("Login failed. Please check your credentials.");
      }

      const data = await response.json();

      if (data && data.user && data.jwt) {
        navigate("/");
        setCurrentUser(data.user);
        localStorage.setItem("jwt", data.jwt);
        localStorage.setItem("kqt_user_id", data.user.id);
        setLogged(true);
      } else {
        console.error("Invalid response data received.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      setLoading(false);
    }
  }

  // !refresh messages
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const token = localStorage.getItem("jwt_token");
    const user_id = localStorage.getItem("kqt_user_id");
    const id = parseInt(user_id);
    fetch(`${apiUrl}/messages/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => {
        if (!resp.ok) {
          console.error("messages error");
          return;
        } else {
          return resp.json();
        }
      })
      .then((d) => {
        setMessages(d);
      });
  }, [currentUser]);

  // !fetches orders if User is true
  useEffect(() => {
    if (currentUser?.role === "admin") {
      fetch(`${apiUrl}/orders/admin_orders`)
        .then((resp) => {
          if (!resp.ok) {
            console.error("Failed to fetch orders.");
          }
          return resp.json();
        })
        .then((data) => {
          setOrders(data);
        });
    } else if (currentUser?.role === "student") {
      const user_id = currentUser.id;
      fetch(`${apiUrl}/orders/${user_id}`)
        .then((resp) => {
          if (!resp.ok) {
            console.error("Failed to fetch orders.");
          }
          return resp.json();
        })
        .then((data) => {
          if (data.length > 0) {
            setOrders(data);
            setCompletedOrdersCount(data.length);
          }
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
        });
    }
  }, [currentUser]);

  // !fetch order packages
  useEffect(() => {
    if (currentUser && currentUser.role === "admin") {
      fetch(`${apiUrl}/order_packages`)
        .then((resp) => {
          if (!resp.ok) {
            console.error("Failed to fetch orders.");
          }
          return resp.json();
        })
        .then((data) => {
          setOrderPackages(data);
        });
    } else if (currentUser && currentUser.role === "student") {
      const id = currentUser.id;
      fetch(`${apiUrl}/order_packages/${id}`)
        .then((resp) => {
          if (!resp.ok) {
            console.error("Failed to fetch orders.");
          }
          return resp.json();
        })
        .then((data) => {
          setOrderPackages(data);
        });
    } else {
      return;
    }
  }, [currentUser]);

  // !fetch order packages
  useEffect(() => {
    fetch(`${apiUrl}/resources`)
      .then((resp) => {
        if (!resp.ok) {
          console.error("Failed to fetch orders:", resp.statusText);
          return;
        }
        return resp.json();
      })
      .then((data) => {
        setResources(data);
        console.log("first RES", data);
      });
  }, [currentUser]);

  // !fetches tasks if User is true
  useEffect(() => {
    if (currentUser && currentUser?.role === "tutor") {
      const tutors_id = currentUser.id;
      const token = localStorage.getItem("jwt");
      fetch(`${apiUrl}/assigned_tasks/${tutors_id}`, {
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => {
          if (!resp.ok) {
            console.error("Failed to fetch orders", resp.statusText);
            return;
          }
          return resp.json();
        })
        .then((data) => {
          setTasks(data);
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
        });
    }
  }, [currentUser]);

  // !greetings
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const today = new Date();
    const todayDate = today.toISOString().split("T")[0];

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const lastLoginDate = formatDate(currentUser.last_log_in);

    if (lastLoginDate === todayDate) {
      setGreeting("Welcome back");
    } else {
      setGreeting("Welcome");
    }
  }, [currentUser]);

  //! users useEffect
  useEffect(() => {
    if (currentUser && currentUser.role === "admin") {
      fetch(`${apiUrl}/users`)
        .then((resp) => resp.json())
        .then((d) => {
          setAllUsers(d);
          console.log("ALLUSERS", d);
        });
    }
  }, [currentUser]);

  //! Reviews useEffect
  useEffect(() => {
    if (currentUser) {
      fetch(`${apiUrl}/reviews`)
        .then((resp) => resp.json())
        .then((d) => {
          setAllReviews(d);
        });
    }
  }, [currentUser]);

  //! Approved Reviews useEffect
  useEffect(() => {
    fetch(`${apiUrl}/approvedReviews`)
      .then((resp) => resp.json())
      .then((d) => {
        setHomeReviews(d);
      });
  }, [currentUser, allReviews]);

  //! Inquiries useEffect
  useEffect(() => {
    if (currentUser && currentUser.role === "admin") {
      fetch(`${apiUrl}/inquiries`)
        .then((resp) => resp.json())
        .then((d) => {
          setInquiries(d);
        });
    }
  }, [currentUser]);

  //! notifications useEffect
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const id = currentUser.id;
    fetch(`${apiUrl}/notifications/${id}`)
      .then((resp) => {
        if (!resp.ok) {
          console.error("notification error");
          setNotifications([]);
          return;
        }
        return resp.json();
      })
      .then((d) => {
        setNotifications(d);
        if (!d) {
          console.log("No notifications found or invalid data.");
          setNotifications([]);
          return;
        }
        setNotifications(d);
      });
  }, [currentUser, notificationsCount]);

  useEffect(() => {
    if (
      !currentUser ||
      !Array.isArray(notifications) ||
      notifications?.length < 1
    ) {
      setNotificationsCount(0);
      return;
    }
    const count = notifications?.filter(
      (n) => !n.isRead && Object.keys(n).length > 0
    ).length;
    setNotificationsCount(count);

    const filtered = notifications?.filter((n) => !n?.isRead);
    setFilteredNotifications(filtered);
  }, [notifications, currentUser]);

  useEffect(() => {
    if (!currentUser || !Array.isArray(messages) || messages?.length < 1) {
      setMessagesCount(0);
      return;
    }
    const count = messages?.filter(
      (n) =>
        !n.isRead && Object.keys(n).length > 0 && n.sender_id !== currentUser.id
    ).length;
    setMessagesCount(count);
  }, [messages, currentUser]);

  //!keeps  orders channel always ON
  useEffect(() => {
    let subscription;

    if (!cable || !currentUser) {
      console.error("Cable or user is not initialized");
      return;
    }

    if (currentUser && currentUser.role === "admin") {
      subscription = cable.subscriptions.create(
        {
          channel: "OrdersChannel",
          admin_id: currentUser.id,
        },
        {
          received: (data) => {
            if (data) {
              setOrders((orders) => [...orders, data]);
            }
          },
        }
      );
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [cable, currentUser]);

  //!keeps  Tasks channel always ON
  useEffect(() => {
    let subscription;
    if (!cable || !currentUser) {
      console.error("Cable or user is not initialized");
      return;
    }
    if (currentUser && currentUser.role === "tutor") {
      subscription = cable.subscriptions.create(
        {
          channel: "TasksChannel",
          tutors_id: currentUser.id,
        },
        {
          received: (data) => {
            if (data) {
              setTasks((tasks) => [...tasks, data]);
            }
          },
        }
      );
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [cable, currentUser]);

  //!keeps  order package channel always ON
  useEffect(() => {
    let subscription;

    if (!cable || !currentUser) {
      console.error("Action Cable connection is not initialized");
      return;
    }

    if (currentUser && currentUser.role === "admin") {
      subscription = cable.subscriptions.create(
        {
          channel: "OrderPackageChannel",
          admin_id: currentUser.id,
        },
        {
          received: (data) => {
            if (data) {
              setOrderPackages((orderPackages) => [...orderPackages, data]);
            }
          },
        }
      );
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [cable, currentUser]);

  //!keeps  order package channel always ON
  useEffect(() => {
    let subscription;

    if (!cable || !currentUser) {
      console.error("Action Cable connection is not initialized");
      return;
    }

    if (currentUser && currentUser.role === "admin") {
      subscription = cable.subscriptions.create(
        {
          channel: "InquiryChannel",
          admin_id: currentUser.id,
        },
        {
          received: (data) => {
            if (data) {
              setInquiries((inquiries) => [...inquiries, data]);
            }
          },
        }
      );
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [cable, currentUser]);

  //!keeps  order package channel always ON
  useEffect(() => {
    let subscription;
    if (!cable || !currentUser) {
      console.error("Action Cable connection is not initialized");
      return;
    }
    if (currentUser && currentUser.role === "student") {
      subscription = cable.subscriptions.create(
        {
          channel: "DeliveryChannel",
          user_id: currentUser.id,
        },
        {
          received: (data) => {
            if (data) {
              setOrderPackages((orderPackages) => [...orderPackages, data]);
            }
          },
        }
      );
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [cable, currentUser]);

  // !message Channel
  useEffect(() => {
    let subscription;
    if (!cable || !currentUser) {
      console.error("Action Cable connection is not initialized");
      return;
    }
    if (currentUser && currentUser.id) {
      subscription = cable.subscriptions.create(
        {
          channel: "MessageChannel",
          receiver_id: currentUser.id,
        },
        {
          received: (message) => {
            if (message) {
              const receiverId = sessionStorage.getItem("receiverId");
              const id = parseInt(receiverId);
              if (message.sender_id === id) {
                toast.success("New Message.");
                setMessages((prevMessages) => [...prevMessages, message]);
              } else {
                console.log("Message from other user, ignored.");
              }
            }
          },
        }
      );
      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, [currentUser, cable]);

  // !message read status channel
  useEffect(() => {
    let subscription;
    if (!cable || !currentUser) {
      console.error("Action Cable connection is not initialized");
      return;
    }
    if (currentUser && currentUser.id) {
      subscription = cable.subscriptions.create(
        {
          channel: "UpdateMessageStatusChannel",
          sender_id: currentUser.id,
        },
        {
          received: (receiver_id) => {
            if (receiver_id) {
              setMessages((prev) =>
                prev.map((msg) => {
                  if (
                    msg.sender_id === currentUser.id &&
                    msg.receiver_id === receiver_id
                  ) {
                    return { ...msg, isRead: true };
                  }
                  return msg;
                })
              );
            }
          },
        }
      );
      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, [currentUser, cable]);

  //!keeps  Notifications channel always ON
  useEffect(() => {
    let subscription;

    if (!cable || !currentUser) {
      console.error("Action Cable connection is not initialized");
      return;
    }
    subscription = cable.subscriptions.create(
      {
        channel: "NotificationsChannel",
        user_id: currentUser.id,
      },
      {
        received: (data) => {
          if (data) {
            setNotifications((prevData) => [...prevData, data]);
          }
        },
      }
    );

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [cable, currentUser]);

  // !updates currentUser account
  async function handleAccountUpdate(formData, user_id) {
    const id = user_id;
    const token = localStorage.getItem("jwt");
    try {
      const response = await fetch(`${apiUrl}/users/${id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        console.error("Failed to update user.");
        return;
      }
      const data = await response.json();
      setCurrentUser(data);
    } catch (error) {
      console.error("Error:", error);
      console.error("An error occurred while updating.");
    }
  }

  // !delete order
  function handleDeleteOrder(q_id) {
    const token = localStorage.getItem("jwt");
    fetch(apiUrl + `/orders/${q_id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          const newReq = orders.filter((req) => req.id !== q_id);
          setOrders(newReq);
        } else {
          console.error("Failed to delete order");
        }
      })
      .catch((error) => {
        console.error("Error occurred during the DELETE request", error);
      });
  }

  // !recent Chats
  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const token = localStorage.getItem("jwt");
    const user_id = localStorage.getItem("kqt_user_id");
    fetch(`${apiUrl}/chatrooms/${user_id}/recent_chats`, {
      headers: {
        "Content-Type": "application/json",
        Accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((d) => {
        setRecentChats(d);
      });
  }, [messages, currentUser]);

  // !send messages
  const sendMessage = async (values) => {
    const token = localStorage.getItem("jwt_token");
    try {
      const resp = await fetch(`${apiUrl}/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          content: values.content,
          receiver_id: values.receiverId,
          sender_id: values.senderId,
        }),
      });

      if (!resp.ok) {
        console.error("Failed to send message:", resp.statusText);
        return;
      }
      const data = await resp.json();
      setMessages((prevMessages) => [...prevMessages, data]);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const readMessage = async (chatroomID) => {
    if (!chatroomID) {
      return;
    }
    const token = localStorage.getItem("jwt_token");
    const id = chatroomID;
    try {
      const resp = await fetch(`${apiUrl}/messages/${id}/mark_as_read`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          receiver_id: currentUser.id,
        }),
      });

      if (!resp.ok) {
        console.error("Failed to send message:", resp.statusText);
        return;
      }
      const data = await resp.json();
      console.log("first");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const toggleSidebar = () => {
    setExpandSidebar((prevVal) => !prevVal);
  };

  const AdminRoutes = () => {
    let admin = currentUser.role === "admin";
    return admin ? <Outlet /> : navigate("/");
  };
  const TutorRoutes = () => {
    let admin = currentUser.role === "tutor";
    return admin ? <Outlet /> : navigate("/");
  };

  const StudentRoutes = () => {
    let student = currentUser.role === "student";
    return student ? <Outlet /> : navigate("/");
  };

  return (
    <div className="m-0 bg-gray-100">
      {!location.pathname.includes("/dashboard") && (
        <Navbar
          className="hidden fixed md:w-full z-50"
          setCurrentUser={setCurrentUser}
          currentUser={currentUser}
          logged={logged}
          setLogged={setLogged}
          notificationsCount={notificationsCount}
          filteredNotifications={filteredNotifications}
          setFilteredNotifications={setFilteredNotifications}
          setNotificationsCount={setNotificationsCount}
          messagesCount={messagesCount}
          notifications={notifications}
          messages={messages}
          sendMessage={sendMessage}
          readMessage={readMessage}
        />
      )}
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path="/"
          element={<Home currentUser={currentUser} homeReviews={homeReviews} />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route
          path="/contact_us"
          element={
            <ContactUs
              currentUser={currentUser}
              setInquiries={setInquiries}
              inquiries={inquiries}
            />
          }
        />
        <Route
          path="/materials"
          element={<StudyMaterials resources={resources} />}
        />
        <Route
          path="/materials/:resource_id"
          element={<StudyMaterialOpen resources={resources} />}
        />
        <Route
          path="/materials/summary/:resource_id"
          element={
            <StudyMaterialSummary
              resources={resources}
              currentUser={currentUser}
            />
          }
        />
        <Route
          path="/order"
          element={
            <Order
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              setLogged={setLogged}
              setOrders={setOrders}
              orders={orders}
              handleSubmit={handleSubmit}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <Dashboard
              expandSidebar={expandSidebar}
              currentUser={currentUser}
              toggleSidebar={toggleSidebar}
              notificationsCount={notificationsCount}
            />
          }
        >
          <Route
            index
            element={
              <Index
                currentUser={currentUser}
                toggleSidebar={toggleSidebar}
                orders={orders}
                orderPackages={orderPackages}
                tasks={tasks}
                greeting={greeting}
                allUsers={allUsers}
                completedOrdersCount={completedOrdersCount}
              />
            }
          />

          
          {/* AdminRoutes */}
          <Route element={<AdminRoutes />}>
            <Route path="/dashboard/assignments" element={<Assignments />} />
            <Route
              path="/dashboard/orders"
              element={<OrdersList orders={orders} />}
            />

            <Route
              path="/dashboard/chatroom"
              element={
                <Chatroom
                  allUsers={allUsers}
                  currentUser={currentUser}
                  messages={messages}
                  setMessages={setMessages}
                  sendMessage={sendMessage}
                  readMessage={readMessage}
                  recentChats={recentChats}
                />
              }
            />
            <Route
              path="/dashboard/review_order"
              element={<ReviewOrderPackage orderPackages={orderPackages} />}
            />
            <Route
              path="/dashboard/reviews"
              element={
                <ReviewsAndRatings
                  allReviews={allReviews}
                  homeReviews={homeReviews}
                  setAllReviews={setAllReviews}
                  setHomeReviews={setHomeReviews}
                />
              }
            />

            <Route
              path="/dashboard/users"
              element={
                <UsersList
                  allUsers={allUsers}
                  handleAccountUpdate={handleAccountUpdate}
                />
              }
            />
            <Route
              path="/dashboard/content"
              element={
                <ContentManagement
                  resources={resources}
                  setResources={setResources}
                />
              }
            />
            <Route
              path="/dashboard/content/edit/:resource_id"
              element={
                <EditStudyMaterial
                  resources={resources}
                  setResources={setResources}
                />
              }
            />
            <Route
              path="/dashboard/content/newResource"
              element={
                <NewResources
                  resources={resources}
                  setResources={setResources}
                  currentUser={currentUser}
                />
              }
            />
            <Route path="/dashboard/reports" element={<ReportAndAnalytics />} />
            <Route
              path="/dashboard/adminSupport"
              element={
                <SupportAndFeedback allUsers={allUsers} inquiries={inquiries} />
              }
            />
            <Route
              path="/dashboard/admin_settings"
              element={
                <AdminSettings
                  currentUser={currentUser}
                  handleAccountUpdate={handleAccountUpdate}
                />
              }
            />
            <Route
              path="/dashboard/single_package"
              element={<ReviewSinglePackage orderPackages={orderPackages} />}
            />
            <Route
              path="/dashboard/orders/:order_id"
              element={
                <OrderAssignment
                  orders={orders}
                  setOrders={setOrders}
                  allUsers={allUsers}
                />
              }
            />
            <Route
              path="/dashboard/notifications"
              element={
                <NotificationPage
                  filteredNotifications={filteredNotifications}
                  notifications={notifications}
                />
              }
            />
          </Route>

          {/* TutorRoutes */}
          <Route element={<TutorRoutes />}>
            <Route
              path="/dashboard/tutor_settings"
              element={
                <TutorProfileSettings
                  handleAccountUpdate={handleAccountUpdate}
                  currentUser={currentUser}
                />
              }
            />
            <Route
              path="/dashboard/resources"
              element={
                <MyResources
                  setResources={setResources}
                  resources={resources}
                />
              }
            />
            <Route
              path="/dashboard/settings"
              element={<TutorProfileSettings />}
            />
            <Route
              path="/dashboard/tasks"
              element={<TutorTasks currentUser={currentUser} tasks={tasks} />}
            />
            <Route
              path="/dashboard/tasks/:task_id"
              element={
                <SingleTask
                  tasks={tasks}
                  orderPackages={orderPackages}
                  setOrderPackages={setOrderPackages}
                  currentUser={currentUser}
                />
              }
            />
          </Route>

          {/* StudentRoutes */}
          <Route element={<StudentRoutes />}>
            <Route
              path="/dashboard/received_packages"
              element={<ReceivedPackages orderPackages={orderPackages} />}
            />
            <Route
              path="/dashboard/messenger"
              element={
                <Messenger
                  currentUser={currentUser}
                  sendMessage={sendMessage}
                  messages={messages}
                  readMessage={readMessage}
                />
              }
            />
            <Route
              path="/dashboard/profileSettings"
              element={
                <ProfileSettings
                  currentUser={currentUser}
                  handleAccountUpdate={handleAccountUpdate}
                />
              }
            />
          </Route>
        </Route>
        <Route
          path="/login"
          element={
            <Login
              handleSubmit={handleSubmit}
            />
          }
        />
      </Routes>
      <Toaster position="top-right" />
    </div>
  );
}

export default App;
