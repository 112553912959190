import React from "react";
import { PaystackButton } from "react-paystack";

function FormSectionThree({
  currentUser,
  orderID,
  amount,
  subject,
  orderType,
  pageCount,
  deliveryTime,
}) {
  const publicKey = process.env.PAYSTACK_PUBLIC_KEY;
  // const publicKey = "pk_test_f31a182972d9734760e69b821d829257636dfc09";
  const email = currentUser ? currentUser.email : "";

  const componentProps = {
    email,
    amount: amount * 100,
    currency: "USD",
    publicKey,
    metadata: {
      order_id: orderID,
      user_id: currentUser.id,
      order:true
    },
    text: "Complete order",
    // onSuccess: () => alert("Payment Successful!"),
    // onClose: () => alert("Payment was not completed"),
  };

  return (
    <div>
      <div className="px-4">
        <div className="bg-white p-4 shadow-md grid grid-cols-12 rounded-md lg:space-x-2  space-y-4">
          <div className="col-span-12 lg:col-span-5">
            <div className="grid grid-cols-1">
              <p className="w-full font-bold text-lg">User information</p>
              <img
                src={
                  currentUser?.image?.length
                    ? currentUser.image
                    : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                }
                alt={currentUser.username}
                className="h-16 w-16 object-cover rounded-full mb-2 border"
              />
            </div>

            <ul className="min-w-full border-collapse space-y-2">
              <li className="flex gap-2">
                <p className=" text-sm/6 font-medium">Username</p>
                <p className=" text-sm/6">{currentUser.username}</p>
              </li>
              <li className="flex gap-2">
                <p className=" text-sm/6 font-medium">Email</p>
                <p className=" text-sm/6">{currentUser.email}</p>
              </li>
            </ul>
          </div>
          <div className="col-span-12 lg:col-span-7">
            <div className="grid grid-cols-1">
              <p className="w-full font-bold text-lg">Order summary</p>
            </div>
            <table className="min-w-full border-collapse">
              <tbody>
                <tr>
                  <td className="py-2 text-sm/6 font-medium" colSpan="2">
                    Type
                  </td>
                  <td className="py-2 text-sm/6">{orderType}</td>
                </tr>
                <tr>
                  <td className="py-2 text-sm/6 font-medium" colSpan="2">
                    Subject
                  </td>
                  <td className="py-2 text-sm/6">{subject}</td>
                </tr>
                <tr>
                  <td className="py-2 text-sm/6 font-medium" colSpan="2">
                    Page Count
                  </td>
                  <td className="py-2 text-sm/6">{pageCount}</td>
                </tr>
                <tr className="border-b">
                  <td colspan="2" className="py-2 text-sm/6 font-medium">
                    Deadline
                  </td>
                  <td className="text-sm/6">
                    {deliveryTime.toLocaleDateString(undefined, {
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    })}
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="uppercase font-medium" colSpan="2">
                    Total
                  </td>
                  <td className="py-2">
                    <span className="text-gray-500">$</span>
                    {amount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-span-12">
            <div className="flex justify-end">
              <div>
                <PaystackButton
                  {...componentProps}
                  className="block w-full  px-4 py-1.5 bg-[#1369A1] text-white rounded-md text-sm/6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSectionThree;
