import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import { UrlContext } from "../context/UrlProvider";
import profile from "../assets/images/profile.jpg"

function Reviews({ user, homeReviews }) {
  const { apiUrl } = useContext(UrlContext);

  const [review, setReview] = useState("");
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  const MIN_LENGTH = 50;
  const MAX_LENGTH = 400;

  const handleReviewChange = (e) => {
    const content = e.target.value;
    if (content.length <= MAX_LENGTH) {
      setReview(content);
    }
  };

  const isReviewValid =
    review.length >= MIN_LENGTH && review.length <= MAX_LENGTH;

  function handleReview(e) {
    e.preventDefault();
    if (!user) {
      return;
    } else if (review === "" || rating === null) {
      return;
    }
    const token = localStorage.getItem("jwt");
    fetch(apiUrl + "/reviews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: user.id,
        review,
        rating,
      }),
    })
      .then((resp) => resp.json())
      .then(() => {
        setRating(null);
        setReview("");
      });
  }

  return (
    <div className="bg-gray-100">
      <div className="flex justify-center items-center min-h-[80vh] p-4">
        <div className="container mx-auto">
          <section>
            <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center py-5">
              <div className="w-full bg-white border-t border-b border-gray-200 px-5 py-16 md:py-24 text-gray-800">
                <div className="w-full max-w-6xl mx-auto">
                  <div className="text-center max-w-xl mx-auto">
                    <motion.h1
                      className="text-6xl md:text-7xl font-bold mb-5 text-gray-600"
                      initial={{ opacity: 0, y: 100 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.73 }}
                      viewport={{ once: true }}
                    >
                      What people <br />
                      are saying.
                    </motion.h1>
                    <h3 className="text-xl mb-5 font-light">
                      Reviews from Students.
                    </h3>
                    <div className="text-center mb-10">
                      <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                      <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                      <span className="inline-block w-40 h-1 rounded-full bg-indigo-500"></span>
                      <span className="inline-block w-3 h-1 rounded-full bg-indigo-500 ml-1"></span>
                      <span className="inline-block w-1 h-1 rounded-full bg-indigo-500 ml-1"></span>
                    </div>
                  </div>
                  <div className="-mx-3 md:flex  items-start flex-wrap px-8">
                    {homeReviews &&
                      homeReviews
                        .map((review) => (
                          <div className="px-3 md:w-1/3">
                            <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6 ">
                              <div className="w-full flex mb-4 items-center">
                                <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                                  <img
                                    src={
                                      review?.image?.length
                                        ? review.user.image
                                        : profile
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="flex-grow pl-3">
                                  <h6 className="font-bold text-sm uppercase text-gray-600">
                                    {review.user?.username}
                                  </h6>
                                </div>
                              </div>
                              <div className="w-full">
                                <p className="text-sm leading-tight">
                                  <span className="text-lg leading-none italic font-bold text-gray-400 mr-1">
                                    "
                                  </span>
                                  {review.content}
                                  <span className="text-lg leading-none italic font-bold text-gray-400 ml-1">
                                    "
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {user && (
        <div className="max-w-4xl mx-auto p-4 pb-8">
          <div className="bg-white shadow-lg rounded-lg">
            <div className="p-6">
              <form onSubmit={(e) => handleReview(e)}>
                <div className="mb-4">
                  <div className="flex gap-4 items-center mb-4">
                    {user && (
                      <div className="h-12 w-12">
                        <img
                          className="h-full w-full object-contain rounded-full"
                          src={user.img || ""}
                          alt="user"
                        />
                      </div>
                    )}
                    <div className="flex flex-col">
                      <label className="text-sm text-gray-700">
                        Rate the App
                      </label>
                      <div className="flex">
                        {[...Array(5)].map((_, index) => {
                          const currentRating = index + 1;
                          return (
                            <label key={index}>
                              <input
                                type="radio"
                                className="hidden"
                                name="rating"
                                value={currentRating}
                                onClick={() => setRating(currentRating)}
                              />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`w-6 h-6 cursor-pointer ${
                                  currentRating <= (hover || rating)
                                    ? "text-yellow-400"
                                    : "text-gray-300"
                                }`}
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onMouseEnter={() => setHover(currentRating)}
                                onMouseLeave={() => setHover(null)}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 17.27l5.18 3.73-1.64-6.57L20.64 9h-6.87L12 2.73 10.23 9H3.36l5.1 5.43-1.64 6.57L12 17.27z"
                                />
                              </svg>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <textarea
                    className="w-full border border-gray-300 rounded-lg p-3"
                    name="review"
                    minLength={MIN_LENGTH}
                    maxLength={MAX_LENGTH}
                    placeholder="Your review must be at least 50 characters"
                    value={review}
                    onChange={(e) => handleReviewChange(e)}
                    rows="4"
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={!isReviewValid}
                    className={`w-full rounded-lg py-2 ${
                      isReviewValid
                        ? "bg-blue-900 text-white"
                        : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reviews;
