import React, { useContext, useEffect, useRef, useState } from "react";
import { UrlContext } from "../../context/UrlProvider";
import RecentChats from "./RecentChats";
import Contacts from "../Contacts";
import { useLocation } from "react-router-dom";

function Chatroom({
  allUsers,
  currentUser,
  setMessages,
  messages,
  sendMessage,
  readMessage,
  recentChats,
}) {
  const apiUrl = useContext(UrlContext);
  const scrollableDivRef = useRef(null);
  const location = useLocation();

  const [hasRead, setHasRead] = useState(false);
  const [content, setContent] = useState("");
  const senderId = currentUser.id;
  const [receiverId, setReceiverId] = useState(() => {
    return sessionStorage.getItem("receiverId") || null;
  });
  const [openContact, setOpenContact] = useState(false);

  const handleFetchMessages = (chatroom_id) => {
    const token = localStorage.getItem("jwt_token");
    fetch(`${apiUrl}/messages/${chatroom_id}/current_messages`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => {
        if (!resp.ok) {
          console.error("messages error");
          return;
        }
        return resp.json();
      })
      .then((d) => {
        if (d.length > 0) {
          setMessages(d);
        }
      });
  };

  useEffect(() => {
    if (
      location.pathname !== "/dashboard/chatroom" ||
      hasRead ||
      receiverId === null
    ) {
      return;
    }
    const chatroom = messages.find(
      (message) =>
        message.receiver_id === receiverId || message.sender_id === receiverId
    );
    if (chatroom) {
      console.log("CHATROOM",chatroom)
      readMessage(chatroom.chatroom_id);
      setHasRead(true);
    }
  }, [receiverId, messages.length]);

  const handleSelectUser = (id) => {
    setReceiverId(id);
    sessionStorage.setItem("receiverId", id);
    setMessages([]);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const storedReceiverId = sessionStorage.getItem("receiverId");

    if (content && receiverId && senderId) {
      sendMessage({ content, receiverId, senderId });
      setContent("");
    } else {
      console.error("Please fill in all fields before sending.");
    }
  };

  const toggleContacts = () => {
    setOpenContact((prevVal) => !prevVal);
  };

  // Scroll to the bottom of messages when new message is added
  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
        setHasRead(false);
    }
  }, [messages.length]);

  return (
    <>
      <div className="container mx-auto">
        {/* <!-- headaer --> */}
        <div className="p-5 flex justify-between items-center bg-white border-b-2">
          <div className="font-semibold text-2xl">
            <div className="flex justify-between items-center space-x-8">
              <p>GoingChat</p>
              <button className=" px-3 py-2" onClick={toggleContacts}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="w-1/2">
            <input
              type="text"
              name=""
              id=""
              placeholder="search IRL"
              className="rounded-2xl bg-gray-100 py-3 px-5 w-full"
            />
          </div>
          <div className="h-12 w-12 p-2 bg-yellow-500 rounded-full text-white font-semibold flex items-center justify-center">
            RA
          </div>
        </div>
        {/* <!-- end header -->
    <!-- Chatting --> */}
        <div className="grid grid-cols-12 justify-between bg-white">
          {/* <!-- chat list --> */}
          <div className="flex flex-row  col-span-2 overflow-y-scroll py-4 px-2">
            {openContact ? (
              <Contacts
                allUsers={allUsers}
                handleSelectUser={handleSelectUser}
              />
            ) : (
              <div className="flex flex-col w-full   overflow-y-auto">
                {/* <!-- search compt --> */}
                {/* <div className="mb-1 ">
                  <input
                    type="text"
                    placeholder="search chatting"
                    className="py-2 px-2 border-2 border-gray-200 rounded-lg w-full"
                  />
                </div> */}

                <div className="flex flex-col px-2 items-center">
                  {recentChats.map((recent) => {
                    return (
                      <div key={recent.id}>
                        <RecentChats
                          recent={recent}
                          handleSelectUser={handleSelectUser}
                          handleFetchMessages={handleFetchMessages}
                          allUsers={allUsers}
                          currentUser={currentUser}
                          readMessage={readMessage}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {/* <!-- end chat list -->
         <!-- message --> */}
          <div className="flex flex-col col-span-7 flex-auto h-full p-6 w-full">
            <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
              <div
                class="flex flex-col max-h-96 h-96 overflow-y-scroll  mb-4"
                ref={scrollableDivRef}
              >
                {messages &&
                  messages.map((message) => {
                    return (
                      <>
                        {currentUser.id === message.sender_id ? (
                          <div className="col-start-6 col-end-13 p-3 rounded-lg ">
                            <div className="flex items-center justify-start flex-row-reverse">
                              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                                A
                              </div>
                              <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                                <div>{message.content}</div>
                                <div className="absolute text-xs bottom-0 right-0 -mb-5 mr-2 text-gray-500">
                                  {message.isRead ? "seen" : "sent"}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-start-1 col-end-8 p-3 rounded-lg ">
                            <div className="flex flex-row items-center">
                              <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                                A
                              </div>
                              <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                                <div>{message.content}</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
              <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 ">
                <div>
                  <button className="flex items-center justify-center text-gray-400 hover:text-gray-600">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="flex-grow ml-4">
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                      onChange={(e) => setContent(e.target.value)}
                    />
                    <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="ml-4">
                  <button
                    className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                    onClick={(e) => handleSendMessage(e)}
                  >
                    <span>Send</span>
                    <span className="ml-2">
                      <svg
                        className="w-4 h-4 transform rotate-45 -mt-px"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- end message --> */}
          <div className="col-span-3 border-l-2 px-5">
            <div className="flex flex-col ">
              <div className="font-semibold text-2xl">Receiver</div>
              <div class="flex flex-col items-center bg-indigo-100 border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg ">
                <div class="h-20 w-20 rounded-full border overflow-hidden">
                  <img
                    src={
                      allUsers[receiverId - 1]?.image.length
                        ? allUsers[receiverId - 1]?.image
                        : "https://avatars3.githubusercontent.com/u/2763884?s=128"
                    }
                    alt="Avatar"
                    class="h-full w-full"
                  />
                </div>
                <div class="text-sm font-semibold mt-2">
                  {allUsers[receiverId - 1]?.username}.
                </div>
                <div class="text-xs text-gray-500 overflow-y-auto">
                  {allUsers[receiverId - 1]?.email}
                </div>
                <div class="flex flex-row items-center mt-3">
                  <div class="flex flex-col justify-center h-4 w-8 bg-indigo-500 rounded-full">
                    <div class="h-3 w-3 bg-white rounded-full self-end mr-1"></div>
                  </div>
                  <div class="leading-none ml-1 text-xs">Active</div>
                </div>
              </div>
              <div className="font-semibold py-4">
                Joined {allUsers[receiverId - 1]?.registration_date}
              </div>
              <div className="font-light">{allUsers[receiverId - 1]?.bio}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chatroom;
