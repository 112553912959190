import { motion } from "framer-motion";
import React, { useState } from "react";
import YouTube from "react-youtube";
import college from "../assets/images/college.jpg";
import about from "../assets/images/about-us.png";
import ribbon from "../assets/images/ribbon.png";
import students from "../assets/images/students.png";
import { useNavigate } from "react-router-dom";
import ourStory from "../assets/images/ourStory.png";
import { ArrowRight } from "react-feather";
import faqs from "../assets/images/faqs.png";
import target from "../assets/images/target.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { PlayIcon } from "@heroicons/react/24/solid";
import Footer from "../components/Footer";

function About({ currentUser }) {

  const [play, setPlay] = useState(false);
  const [currentCard, setCurrentCard] = useState("card1");

  function handleCollapse(card) {
    setCurrentCard(card);
  }

  const navigate = useNavigate();
  const percentage = 65;

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  function onReady(e) {
    e.target.pauseVideo();
  }

  const people = [
    {
      name: "Leslie Alexander",
      role: "Co-Founder / CEO",
      imageUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      name: "Michael Foster",
      role: "Co-Founder / CEO",
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      name: "Dries Vincent",
      role: "Business Relations",
      imageUrl:
        "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      name: "Lindsay Walton",
      role: "Front-end Developer",
      imageUrl:
        "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      name: "Courtney Henry",
      role: "Designer",
      imageUrl:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      name: "Tom Cook",
      role: "Director of Product",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  ];

  return (
    <main>
      <section>
        <div className="md:container md:mx-auto px-2 sm:px-12 md:px-24 pt-32">
          <div className="grid grid-cols-12 ">
            <div className="col-span-12 text-center">
              <p className="text-center font-bold mb-5">Our Story</p>
              <motion.p
                className="font-bold text-center text-4xl"
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.83 }}
                viewport={{ once: true }}
              >
                Your Premier Destination for Educational Excellence
              </motion.p>
            </div>
          </div>
          <div className="grid grid-cols-12 justify-between xl:mb-36">
            <div className="relative col-span-5 rounded py-5 ">
              <img
                className="w-full h-full object-contain rounded-lg"
                src={about}
                alt="info"
              />

              <div className="absolute bottom-[-30px] left-0 px-2 w-2/3 shadow-lg bg-white rounded-lg">
                <div className="flex gap-3 w-full justify-center items-center">
                  <img className="w-1/4" src={ribbon} alt="Title" />
                  <div className="text-start">
                    <p className="text-4xl font-bold m-0">10+</p>
                    <p className="m-0">Years of Excellence</p>
                  </div>
                </div>
              </div>

              <div className="absolute top-[65%] right-[-30%] hidden xl:flex ">
                <div className="w-72 h-72 p-1">
                  <img
                    className="w-full h-full object-cover   rounded-xl border-2"
                    src={students}
                    alt="Title"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-5 col-start-8 text-start pt-5 xl:pt-0 ">
              <motion.h1
                className="about text-4xl font-bold my-8"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.93 }}
                viewport={{ once: true }}
              >
                <span>About Us</span>
              </motion.h1>
              <motion.p
                className="about_mission"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.83 }}
                viewport={{ once: true }}
              >
                Our Mission is to empower learners by providing personalized,
                accessible, and effective online tutoring
              </motion.p>
              <motion.p
                className="text-muted "
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.73 }}
                viewport={{ once: true }}
              >
                We are driven by the belief that education has the power to
                shape a brighter future, and we are committed to making a
                positive impact on the lives of our students, helping them
                achieve their full potential.
              </motion.p>
              <motion.button
                className="btn border-black rounded-0 mt-4"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.73 }}
                viewport={{ once: true }}
                onClick={() => navigate("/about#faqs")}
              >
                <span className="flex">
                  FAQs <ArrowRight />
                </span>
              </motion.button>
            </div>
          </div>
          <div className="grid grid-cols-12 justify-between">
            <div className="col-span-5 text-start pt-5 xl:pt-0">
              <motion.h2
                className="about text-3xl font-bold my-8"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.93 }}
                viewport={{ once: true }}
              >
                <span>Our vision</span>
              </motion.h2>
              <motion.p
                className="about_mission text-4xl leading-tight mb-4"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.83 }}
                viewport={{ once: true }}
              >
                Elevate Your
                <span className="text-yellow-400"> Career </span>
                Learning Experience.
              </motion.p>
              <motion.p
                className=" m-0"
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.73 }}
                viewport={{ once: true }}
              >
                We are driven by the belief that education has the power to
                shape a brighter future, and we are committed to making a
                positive impact on the lives of our students, helping them
                achieve their full potential.
              </motion.p>
              <ul className="list-none mt-3">
                <li className=" text-gray-500">
                  The foundation for a lifetime of success
                </li>
                <li className=" text-gray-500">
                  Find the right instructor for you
                </li>
                <li className=" text-gray-500">Popular topics to learn now</li>
              </ul>
            </div>

            <div className="relative col-span-5 col-start-7 py-5">
              <div className="h-5/6  w-3/4">
                <img
                  className="w-full h-full  rounded-lg "
                  src={ourStory}
                  alt="info"
                />
              </div>

              <div className="absolute bottom-[15vh] right-[8vw] px-2 w-3/4 shadow-lg bg-white rounded-lg">
                <div className="flex gap-3 justify-center items-center p-4">
                  <div className="w-1/5 text-lg ">
                    <CircularProgressbar
                      value={percentage}
                      text="97%"
                      className="text-lg"
                    />
                  </div>
                  <div className="text-start">
                    <p className=" text-gray-400 font-semibold m-0">
                      Student Story
                    </p>
                    <p className="text-lg font-semibold m-0">
                      Student Success History
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="md:container md:mx-auto my-4">
          <div className="relative mx-auto w-3/4 h-[500px] max-h-[500px] overflow-hidden red">
            {/* Background Image */}
            <img
              alt="College view"
              src={college}
              className={`absolute inset-0 -z-1 h-full w-full object-cover object-center ${
                play ? "hidden" : ""
              }`}
            />

            {/* Play Button */}
            {!play && (
              <button
                onClick={() => setPlay(true)}
                className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50"
              >
                <PlayIcon className="h-20 w-20 text-red-600" />
              </button>
            )}

            {/* YouTube Video */}
            {play && (
              <div className="absolute inset-0 z-20 w-full h-full">
                <YouTube
                  videoId="_fuPbLWKtNE"
                  opts={opts}
                  onReady={(e) => onReady(e)}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <section>
        <div className="md:container md:mx-auto sm:px-24 py-20">
          <div className="mx-auto grid max-w-7xl gap-20 px-6 lg:px-8 xl:grid-cols-3">
            <div className="max-w-xl">
              <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                Meet our leadership
              </h2>
              <p className="mt-6 text-lg/8 text-gray-600">
                We’re a dynamic group of individuals who are passionate about
                what we do and dedicated to delivering the best results for our
                clients.
              </p>
            </div>
            <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
              {people.map((person) => (
                <li key={person.name}>
                  <div className="flex items-center gap-x-6">
                    <img
                      alt=""
                      src={person.imageUrl}
                      className="h-20 w-20 rounded-full"
                    />
                    <div>
                      <h3 className="text-base/7 font-semibold tracking-tight text-gray-900">
                        {person.name}
                      </h3>
                      <p className="text-sm/6 font-semibold text-indigo-600">
                        {person.role}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-pink-100">
        <div className="md:container md:mx-auto sm:px-24 py-12 ">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-between items-start gap-8">
              <div className="w-1/3 h-1/3 rounded-xl overflow-hidden">
                <img
                  className="w-full h-full object-contain rounded-lg"
                  src={faqs}
                  alt="info"
                />
              </div>
              <div className="w-full md:w-6/12 text-left">
                <motion.p
                  className="text-3xl font-bold text-yellow-500 mb-2"
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.93 }}
                  viewport={{ once: true }}
                >
                  <span>FAQs</span>
                </motion.p>
                <motion.h3
                  className="text-4xl font-semibold leading-tight mb-6"
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.83 }}
                  viewport={{ once: true }}
                >
                  Frequently Asked Questions
                </motion.h3>

                <div className="space-y-4">
                  <div
                    className="bg-white p-4 rounded-lg shadow-md cursor-pointer transition-all duration-300 ease-in-out"
                    onClick={() => handleCollapse("card1")}
                  >
                    <div className="flex items-center">
                      <p
                        className={`font-bold text-lg ${
                          currentCard === "card1" ? "text-yellow-500" : ""
                        }`}
                      >
                        1. &nbsp; Why choose us for your education?
                      </p>
                    </div>
                    <div
                      className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                        currentCard === "card1" ? "max-h-40" : "max-h-0"
                      }`}
                    >
                      <p className="mt-2 ml-2 text-gray-700">
                        We unleash your potential with personalized online
                        tutoring. Our dedicated educators tailor lessons to your
                        needs, fostering a dynamic learning experience that
                        ensures academic excellence.
                      </p>
                    </div>
                  </div>

                  <div
                    className="bg-white p-4 rounded-lg shadow-md cursor-pointer transition-all duration-300 ease-in-out"
                    onClick={() => handleCollapse("card2")}
                  >
                    <div className="flex items-center">
                      <p
                        className={`font-bold text-lg ${
                          currentCard === "card2" ? "text-yellow-500" : ""
                        }`}
                      >
                        2. &nbsp; What We Offer To You?
                      </p>
                    </div>
                    <div
                      className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                        currentCard === "card2" ? "max-h-40" : "max-h-0"
                      }`}
                    >
                      <p className="mt-2 ml-2 text-gray-700">
                        We elevate your education with diverse subjects and
                        personalized instruction. Access top-notch educators
                        worldwide and embark on a journey of academic
                        enrichment. Uncover a world of knowledge designed just
                        for you.
                      </p>
                    </div>
                  </div>

                  <div
                    className="bg-white p-4 rounded-lg shadow-md cursor-pointer transition-all duration-300 ease-in-out"
                    onClick={() => handleCollapse("card3")}
                  >
                    <div className="flex items-center">
                      <p
                        className={`font-bold text-lg ${
                          currentCard === "card3" ? "text-yellow-500" : ""
                        }`}
                      >
                        3. &nbsp; How We Provide Services For You?
                      </p>
                    </div>
                    <div
                      className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                        currentCard === "card3" ? "max-h-40" : "max-h-0"
                      }`}
                    >
                      <p className="mt-2 ml-2 text-gray-700">
                        We prioritize your success. From tailored lessons to
                        expert guidance, we ensure a seamless learning
                        experience. Join us to explore, learn, and achieve your
                        educational goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default About;
