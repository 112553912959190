import React from "react";
import { useNavigate } from "react-router-dom";
import premium from "../assets/images/premium.png";

function StudyMaterials({ resources }) {
  const navigate = useNavigate();



  const handleOpenOrder = (value) => {
    console.log("first");
    const resource_id = value.id;
      navigate(`/materials/:${resource_id}`);
  };

  const truncatedText = (text) => {
    const truncated = text.length > 50 ? text.substring(0, 50) + " ..." : text;
    return truncated
  }

  return (
    <div className="bg-[#f8ecef] h-full">
      <div className="md:container md:mx-auto px-2 sm:px-12  md:px-18">
        <div className="section courses-section  py-20">
          <div className="container mx-auto py-5 space-y-12">
            <div className="grid">
              <div className="col-12 text-center">
                <div className="mx-auto max-w-xl text-center">
                  <h4 className="text-lg font-semibold text-center text-gray-700 mb-2">
                    <span>Study Resources</span>
                  </h4>
                  <h2 className="text-3xl font-bold">
                    Most Popular Disciplines
                  </h2>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-start justify-center gap-4 sm:gap-8 ">
              {/* Dynamic cards for topicsArr */}
              {resources &&
                resources.map((resource, index) => (
                  <div key={index} className="w-70  sm:w-64 relative">
                    {resource.amount !== "undefined" && (
                      <div className="absolute h-8 w-8 top-1">
                        <img
                          className="object-contain "
                           src={premium}
                          alt="resource.subject"
                        />
                      </div>
                    )}
                    <article className="flex flex-col dark:bg-gray-50">
                      <div onClick={() => handleOpenOrder(resource)}>
                        <img
                          alt=""
                          className="object-cover w-full h-52 dark:bg-gray-500"
                          src={
                            resource.cover_image?.length
                              ? resource.cover_image
                              : "https://picsum.photos/seed/59/300/200"
                          }
                        />
                      </div>
                      <div className="flex flex-col flex-1 p-2">
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          className="text-xs tracking-wider uppercase hover:underline dark:text-violet-600"
                        >
                          {resource.content_type}
                        </a>
                        <h3
                          className="flex-1 pt-2 text-lg font-semibold leading-snug whitespace-normal break-words"
                          onClick={() => handleOpenOrder(resource)}
                        >
                          {truncatedText(resource.subject)}
                        </h3>
                        <div className="flex flex-wrap justify-between pb-3 space-x-2 text-xs dark:text-gray-600">
                          <span>
                            {resource.page_count}
                            {resource.page_count > 1 ? "pages" : "page"}
                          </span>
                          <span>
                            {resource.review?.length && (
                              <p className="text-sm text-gray-500">
                                {resource.review.length}
                                {resource.review > 1 ? "reviews" : "review"}
                              </p>
                            )}
                          </span>
                        </div>
                      </div>
                    </article>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudyMaterials;
