import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { UrlContext } from "../../context/UrlProvider";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

function OrderAssignment({ orders, setOrders, allUsers }) {
  const { order_id } = useParams();
  const id = parseInt(order_id.split(":")[1]);
  // localStorage.setItem("taskId", id);
  // const taskId = localStorage.getItem("taskId");
  const order = orders.find((task) => task.id === id);
  const sanitizedContent = DOMPurify.sanitize(order?.order_details);

  const [openWriters, setOpenWriters] = useState(false);
  const [selected_id, setSelected_id] = useState();

  const [content, setcontent] = useState("");
  const [error, setError] = useState([]);

  const [open, setOpen] = useState(true);

  const apiUrl = useContext(UrlContext);

  const assignees = allUsers.filter(
    (user) => user.role === "tutor" || user.role === "admin"
  );

  const ProcessTask = async (e, id) => {
    e.preventDefault();
    if (!selected_id) {
      alert("selectee empty");
      return;
    }

    const token = localStorage.getItem("jwt");
    try {
      const response = await fetch(`${apiUrl}/assign_order/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accepts: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          order_status: "processing",
          tutors_id: selected_id,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        // Update the status of the order with the given id
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === id ? { ...order, order_status: "processing" } : order
          )
        );
        console.error(data.errors);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleSubmitResource = (e, order_id) => {
    setError("");
    if (!content) {
      setError("content is empty");
      return;
    }
    e.preventDefault();
    const token = localStorage.getItem("jwt");
    fetch(apiUrl + `/packages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accepts: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        content: content,
        order_id: order_id,
      }),
    })
      .then((resp) => {
        if (!resp.ok) {
          return resp.json().then((errorData) => {
            setError(errorData.message);
            throw new Error(errorData.message || "Something went wrong");
          });
        }
        return resp.json();
      })
      .then((data) => {
        const updatedRequests = orders.map((order) => {
          if (order.id === data.order.id) {
            return data;
          } else {
            return order;
          }
        });
        // Set the updated state
        setOrders(() => updatedRequests);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        setError(error.message); // Set error state with the error message
      });
  };

  const handleOpenWriterOverlay = () => {
    setOpenWriters((prevVal) => !prevVal);
  };

  return (
    <>
      <div
        className="bg-gray-100 p-4 m-8 text-left shadow-md "
        style={{ boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px" }}
      >
        {openWriters && (
          <div className="overlay fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
            <div className="rounded-lg shadow-lg max-w-md w-full p-4 bg-white mx-4 sm:mx-0">
              <div className="flex justify-end mb-4">
                <button
                  className="text-gray-500 hover:text-gray-900 focus:outline-none"
                  onClick={() => handleOpenWriterOverlay()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="text-center space-y-2">
                <div className="bg-white rounded shadow-md p-4 w-full">
                  <h5 className="font-bold text-lg text-gray-800">
                    Assign Task to Writer
                  </h5>
                  <p className="text-sm text-gray-600 mt-2">
                    <em>
                      Select a writer to work on the task. They will be notified
                      immediately.
                    </em>
                  </p>
                  <form className="mt-4">
                    <div className="mb-4">
                      <label className="block text-gray-700 font-medium mb-2">
                        Writers
                      </label>
                      <select
                        className="form-select block w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={(e) => setSelected_id(e.target.value)}
                        required
                      >
                        <option value="" disabled selected>
                          Select one
                        </option>
                        {assignees.map((user) => (
                          <option
                            className="capitalize"
                            value={user.id}
                            key={user.id}
                          >
                            {user?.username}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex">
                      <button
                        type="button"
                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 w-full rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={(e) => ProcessTask(e, order.id)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        <section>
          <div>
            <div className="px-4 sm:px-0">
              <h3 className="text-base/7 font-semibold text-gray-900">
                Order Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm/6 text-gray-500">
                order details
              </p>
            </div>
            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Order type
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {order?.order_type}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Discipline
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {order?.subject}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {order?.user?.username}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Amount
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                    ${order?.amount}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Order status
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {order && (
                      <span
                        className={`inline-flex items-center rounded-full px-2 font-medium ring-1 ring-inset ${
                          order.order_status === "processing"
                            ? "bg-blue-50 text-blue-600 ring-blue-500/10"
                            : order.order_status === "complete"
                            ? "bg-green-50 text-green-600 ring-green-500/10"
                            : order.order_status === "pending"
                            ? "bg-purple-50 text-purple-600 ring-purple-500/10"
                            : order.order_status === "cancelled"
                            ? "bg-red-50 text-red-600 ring-red-500/10"
                            : "bg-gray-50 text-gray-600 ring-gray-500/10" // Fallback for any unknown status
                        }`}
                      >
                        {order?.order_status}
                      </span>
                    )}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Details
                  </dt>
                  <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                    <div
                      className="whitespace-pre-wrap break-words"
                      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                    ></div>
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm/6 font-medium text-gray-900">
                    Attachments
                  </dt>
                  <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                      {order?.files && (
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                            />
                          </svg>
                          {order?.files?.map((file, index) => (
                          <div
                            className="p-1 card overflow-hidden "
                            key={index}
                          >
                            <li className="flex items-center justify-between  px-5 text-sm/6">
                              <div>
                                <div className="flex flex-col justify-start">
                                  {file.content_type.startsWith("image/") && (
                                    <div>
                                      <img
                                        src={file.url}
                                        alt={file.name}
                                        className="w-full h-auto mt-2 rounded"
                                        style={{
                                          maxHeight: "20vh",
                                          maxWidth: "20vh",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div className="flex-shrink-0 mt-2">
                                    <a
                                      href={file.url}
                                      download
                                      className="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      Download
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                          ))}
                        </div>
                      )}
                    </ul>
                  </dd>
                </div>
                {order?.order_status === "pending" && (
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded"
                      onClick={() => handleOpenWriterOverlay()}
                    >
                      Assign order
                    </button>
                  </div>
                )}
              </dl>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default OrderAssignment;
