import React, { useContext, useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { topicsArr } from "../../assets/ArrayData/topicsArr";
import { PhotoIcon } from "@heroicons/react/20/solid";
import { UrlContext } from "../../context/UrlProvider";

function EditStudyMaterial({ resources, setResources }) {
  const { resource_id } = useParams();
  const id = parseInt(resource_id.split(":")[1]);
  const navigate = useNavigate();
  const apiUrl = useContext(UrlContext);

  const resource = resources.find((resource) => resource.id === id);

  const [subject, setSubject] = useState(resource?.subject || "");
  const [contentDetails, setContentDetails] = useState(
    resource?.content_details || ""
  );
  const [pageCount, setPageCount] = useState(1);
  const [contentType, setContentType] = useState(resource?.content_type || "");
  const [singleSpaced, setSingleSpaced] = useState(
    resource?.single_spaced || false
  );
  const [sourceCount, setSourceCount] = useState(resource?.source_count || 0);
  const [amount, setAmount] = useState(resource?.amount || 0);
  const [coverImage, setCoverImage] = useState(null);
  const [file, setFile] = useState("");

  const handlePageCount = (operator) => {
    setPageCount((prevCount) => {
      let count = prevCount;
      if (operator === "+") {
        count += 1;
      } else if (operator === "-" && prevCount > 1) {
        count -= 1;
      }
      return count;
    });
  };

  const handleSingleSpaced = () => {
    setSingleSpaced((prevVal) => !prevVal);
  };

  const handleSourceCount = (operator) => {
    setSourceCount((prevCount) => {
      let count = prevCount;
      if (operator === "+") {
        count += 1;
      } else if (operator === "-" && prevCount > 0) {
        count -= 1;
      }
      return count;
    });
  };

  const handleRemoveImage = () => {
    setCoverImage(null); // Reset the image state
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setCoverImage({
        url: URL.createObjectURL(file),
        name: file.name,
        preview: ["jpg", "jpeg", "png", "gif"].includes(
          file.name.split(".").pop().toLowerCase()
        ),
        size:
          file.size > 1024
            ? file.size > 1048576
              ? Math.round(file.size / 1048576) + " MB"
              : Math.round(file.size / 1024) + " KB"
            : file.size + " B",
      });

      console.log("READY", file);
    }
  };

  const handleResourceSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("content_type", contentType.value);
    formData.append("content_details", contentDetails);
    formData.append("cover_image", file);
    formData.append("amount", amount);
    formData.append("single_spaced", singleSpaced);
    formData.append("source_count", sourceCount);
    formData.append("page_count", pageCount);

    const token = localStorage.getItem("jwt_token");
    const response = await fetch(`${apiUrl}/resources/${id}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    if (data) {
      const values = resources.map((resource) => {
        if (resource.id === id) {
          return data;
        } else {
          return resource;
        }
      });
      setResources(values);
      handleCancelForm();
    } else {
      throw new Error("Invalid response data received.");
    }
  };

  const handleCancelForm = () => {
    localStorage.setItem("content_quill_content", "");
    setCoverImage("");
    setSubject("");
    setContentType("");
    setContentDetails("");
    setSingleSpaced(false);
    setSourceCount(0);
    setPageCount(1);
    setAmount(0);
    navigate("/dashboard/content");
  };

  useEffect(() => {
    const value = topicsArr.find(
      (topic) => topic.value === resource?.content_type
    );
    setContentType(value);
  }, [resource]);

  return (
    <div>
      <div className="bg-white">
        <form>
          <div className="px-8 py-20 grid grid-cols-12 gap-8">
            <div className="md:col-span-4 col-span-12">
              <label
                htmlFor="username"
                className="block text-sm/6 font-medium text-gray-900"
              >
                Subject
              </label>
              <input
                type="text"
                name="subject"
                id="price"
                value={subject}
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 "
                onChange={(e) => {
                  setSubject(e.target.value);
                }}
              />
            </div>
            <div className="md:col-span-4 col-span-12">
              <label className="form-label ">
                Content type<span className="text-danger">*</span>
              </label>

              <Select
                value={contentType}
                onChange={(e) => {
                  setContentType(e);
                }}
                options={topicsArr}
              />
            </div>

            <div className="md:col-span-3  col-span-6 md:col-start-1">
              <label for="soda">Page count</label>
              <div className="h-10 w-28 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                <button
                  type="button"
                  className="cursor-pointer outline-none focus:outline-none border-r border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                  onClick={() => handlePageCount("-")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="h-4 w-4 mx-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 12h14"
                    />
                  </svg>
                </button>
                <input
                  className="px-2 text-center appearance-none outline-none text-gray-800 w-full bg-transparent"
                  value={pageCount}
                />
                <button
                  type="button"
                  className="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                  onClick={() => handlePageCount("+")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="h-4 w-4 mx-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="md:col-span-3 mt-6 ">
              <div className="inline-flex items-center">
                <input
                  type="checkbox"
                  value="singleSpaced"
                  checked={singleSpaced}
                  onChange={handleSingleSpaced}
                />
                <label
                  className="form-check-label  text-black ml-2"
                  onChange={handleSingleSpaced}
                >
                  Single spaced
                </label>
              </div>
            </div>
            <div className="md:col-span-3  col-span-6 md:col-start-1">
              <label className="block font-medium mb-1">
                Number of sources
              </label>

              <div className="h-10 w-28 bg-gray-50 flex border border-gray-200 rounded items-center mt-1">
                <button
                  type="button"
                  className="cursor-pointer outline-none focus:outline-none border-r border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                  onClick={() => handleSourceCount("-")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="h-4 w-4 mx-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 12h14"
                    />
                  </svg>
                </button>
                <input
                  placeholder="0"
                  className="px-2 text-center appearance-none outline-none text-gray-800 w-full bg-transparent"
                  value={sourceCount}
                />
                <button
                  type="button"
                  className="cursor-pointer outline-none focus:outline-none border-l border-gray-200 transition-all text-gray-500 hover:text-blue-600"
                  onClick={() => handleSourceCount("+")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="h-4 w-4 mx-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="sm:col-span-4">
              <div className="flex flex-col space-y-2">
                <label className="block font-medium mb-1">Amount</label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    placeholder="0.00"
                    className="block w-full pl-10 pr-4 py-2 text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-span-12">
              <ReactQuill
                theme="snow"
                value={contentDetails}
                onChange={(value) => setContentDetails(value)}
                className="custom-quill-editor"
              />
            </div>
            <div className="col-span-full">
              <label
                htmlFor="cover-photo"
                className="block text-sm/6 font-medium text-gray-900"
              >
                Cover photo
              </label>
              <div className="w-full mt-2">
                {!coverImage && (
                  <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                      <PhotoIcon
                        aria-hidden="true"
                        className="mx-auto size-12 text-gray-300"
                      />
                      <div className="mt-4 flex text-sm/6 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="sr-only"
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs/5 text-gray-600">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                )}
                {/* Image Preview */}
                {coverImage && (
                  <div className="mt-4 flex space-x-4 h-96 w-96">
                    {coverImage.preview ? (
                      <img
                        src={coverImage.url}
                        alt={coverImage.name}
                        className="h-full w-full object-contain rounded-md mb-2 "
                      />
                    ) : (
                      <div className="h-20 w-20 flex items-center justify-center bg-gray-100 rounded mb-2">
                        <span className="text-sm text-gray-500">
                          Unsupported
                        </span>
                      </div>
                    )}
                    <div>
                      <div className="text-xs text-gray-500 truncate">
                        {coverImage.name} ({coverImage.size})
                      </div>
                      <button
                        className="mt-2 px-4 py-1 bg-red-500 text-white text-xs rounded"
                        onClick={handleRemoveImage}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="md:col-span-6  col-span-12 md:col-start-7">
              <div className="my-6 flex items-center justify-between md:justify-end gap-x-12">
                <button
                  type="button"
                  className="text-sm/6 font-semibold text-gray-900 border px-2 py-1.5 border-gray-400 rounded-md"
                  onClick={handleCancelForm}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-2 py-1.5 text-sm/6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={(e) => handleResourceSubmit(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditStudyMaterial;
